import React from "react";
export const PaymentMethod = ({
  icon,
  method,
  selectedMethod,
  setSelectedMethod,
  cart,
  disabled = false,
  setError,
}) => {
  let textCarte = "font-semibold mx-[10px] text-lg";
  let styleCarte = `flex  items-center py-[26px] xxs:px-[32px] px-[10px] ${
    disabled ? "hidden " : "block hover:shadow-boxHover hover:border-[#0066FF]"
  }  shadow-box  border mt-[14px] rounded-[20px] `;
  return (
    <div
      onClick={() => {
        setSelectedMethod(method);
        if (method === "PAYPAL") setError(false);
      }}
      className={`${styleCarte} ${
        selectedMethod === method ? "shadow-boxHover border-[#0066FF]" : ""
      }`}
    >
      <div>{icon}</div>
      <div className={textCarte}>{cart} </div>
    </div>
  );
};
