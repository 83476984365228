import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ReactComponent as Kio } from "../image/svg/kio.svg";
import { useForm } from "react-hook-form";
import frame from "../image/Frame.png";
import { getUserInformations, signIn } from "../utils/services";
import { Button } from "../component/common/Button";
import { Input } from "../component/common/Input";
import { InputPassword } from "../component/common/InputPassword";
import { useTranslation } from "react-i18next";

export default function SignIn() {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm({ mode: "onChange" });

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [innerLoading, setInnerLoading] = useState(false);

  const onSubmit = async (data) => {
    setInnerLoading(true);
    try {
      await signIn(data);
      await getUserInformations(dispatch);
      setInnerLoading(false);
      navigate(`/`);
    } catch (error) {
      console.log(error);
      setError("username", {
        type: "manual",
        message: error?.message,
      });
      setError("password", {
        type: "manual",
        message: error?.message,
      });
      setInnerLoading(false);

    }
  };
  return (
    <div className="flex flex-col overflow-hidden 3xl:items-center  justify-center min-h-screen">
      <div
        id="sign-In"
        className="p-5 lg:flex max-w-[1650px]  justify-between bg-bgImageA bg-no-repeat bg-cover "
      >
        <div className="lg:flex  flex-1 hidden items-center justify-center content-center">
          <img alt="imgSignIn" src={frame} />
        </div>
        <div className=" justify-center flex flex-col  lg:items-stretch items-center flex-1 2xl:justify-center lg:justify-end">
          <div
            style={{ maxWidth: "600px" }}
            id="logo"
            className="p-8 flex justify-center items-center"
          >
            <Kio />
          </div>
          <div
            style={{ maxWidth: "600px" }}
            id="form"
            className="border rounded-[15px] shadow-shadowFram flex flex-col justify-center p-[30px] md:p-[60px]"
          >
            <div className=" flex justify-center font-medium text-black text-2xl md:text-3xl ">
              {t("signIn.login")}
            </div>

            <form onSubmit={handleSubmit(onSubmit)}>

              <div className="mb-[11px] mt-7">
              {(errors?.username && errors?.password) && <div className="text-xs text-red-500 mb-[15px]">{t("signIn.invalidValue")}</div>}
                <div className="mb-[15px]" >
                  <Input
                  error={(errors?.username?.message.length>0 && errors?.password?.message.length>0)}
                    placeholder={t("signIn.userName")}
                    register={register("username", {
                      required: true,
                      maxLength: 40,
                    })}
                  />
                </div>
                <div className="flex justify-end text-sm mb-1 cursor-pointer hover:text-black text-[#959595]">

                  <a target="_blanck" href="https://profile.kiostore.net/recover-account">{t("signIn.forgetPassword")}</a>
                    
                </div>

                <InputPassword
                error={(errors?.username?.message.length>0 && errors?.password?.message.length>0)}
                  placeholder={t("signIn.password")}
                  register={register("password", {
                    required: true,
                    // minLength: 8,
                  })}
                />
              </div>

              <div className="w-full py-[16px]">
                <Button loading={innerLoading} disabled={innerLoading}>{t("signIn.toLogin")}</Button>
              </div>
              <div className="my-4  flex justify-center items-center text-[#757575]">
                {" "}
                <p>
                  {t("signIn.noAccount")}{" "}
                  <span
                    className="cursor-pointer text-[#0066FF]"
                    onClick={() => {
                      navigate(`/signup`);
                    }}
                  >
                    {t("signIn.signUp")}
                  </span>
                </p>{" "}
              </div>
            </form>
          </div>
          <div className="flex justify-center items-center my-5 mt-[55px]">
            <div className="flex justify-around text-[#ABABAB] text-xs cursor-pointer">
              <div className="flex px-6">
                <div
                  onClick={() => {
                    navigate("politique");
                  }}
                >
                  {t("signIn.politics")}
                </div>
              </div>
              <div className="flex px-6 ">
                <div
                  onClick={() => {
                    navigate("mention");
                  }}
                >
                  {t("signIn.mention")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
