import React from "react";
import { Button } from "./Button";
import numeroVert from "../../image/cib_number.png";

export function DisplayStatus({
  title,
  children,
  onSubmit,
  childrenButton,
  primary,
}) {
  return (
    <>
      <div className="flex justify-center items-center md:text-xl  my-2">
        {title}
      </div>
      <div className="flex-1 flex  flex-col justify-center items-center my-3">
        {children}
      </div>
      <div className=" my-2 flex  justify-center">
        <div
          className={`flex items-center ${
            primary ? "justify-between space-x-4" : "justify-center"
          }  px-2`}
        >
          {primary && <img src={numeroVert} alt="Numéro vert " />}
          <Button size={primary ? "small" : ""} submit={onSubmit}>
            {childrenButton}
          </Button>
        </div>
      </div>
    </>
  );
}
