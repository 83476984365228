import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Text } from "../common/Text";
import { Button } from "../common/Button";
import { Space } from "../common/Space";
import { CodeInput } from "../common/codeInput";
import Banner from "../common/Banner";
import { confirmPhone, logout } from "../../utils/services";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";


export function StepThree({ method, setConfirmationStep, setShowTimer }) {
  const {t}=useTranslation()
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [code, setCode] = useState([]);
  const [error, setError] = useState("");

  const onConfirmPhone = async () => {
    try {
      if (code.join("").length === 6) {
        let data = {
          email: method === 0 ? user?.email : null,
          phone_number: method === 0 ? null : user?.phone_number,
          confirmation_code: code.join(""),
          resend: false,
        };
        await confirmPhone(data, user, dispatch);
        navigate(`/`)
      } else {
        setError(t("commons.incompleteCode"));
      }
    } catch (error) {
      console.log(error);
      setError(error?.message);
    }
  };
  return (
    <div>
      <Text size="sm" color="text-gray-400">
       {t("confirmAccount.sent")} {method === 0 ? user?.email : user?.phone_number}
      </Text>
      <Space vertical="2">{error && <Banner text={error} />}</Space>
      <CodeInput setCode={setCode} code={code} onChange={() => setError("")} />
      <div className="flex flex-col sm:flex-row">
        <div className="flex flex-auto items-center">
          <Text size="sm" color="text-gray-400">
           {t("confirmAccount.didn'tReceiveCode")}{" "}
            <span
              onClick={() => {
                setConfirmationStep(1);
                setShowTimer(true);
              }}
              className="text-kio-500 font-bold cursor-pointer hover:text-indigo-700"
            >
              {t("commons.reOrder")}
            </span>
          </Text>
        </div>
        <div className="flex flex-auto py-4 sm:py-0 justify-end">
          <div className="mx-2">
            <Button primary={false} submit={() => logout(navigate, dispatch)}>
            {t("commons.logout")}
            </Button>
          </div>

          <div className="mx-2">
            <Button
              submit={() => {
                onConfirmPhone();
              }}
            >
              {t("commons.check")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
