import React from "react";

import { useTranslation } from "react-i18next";
import errorIllustration from "../image/svg/404.svg";
import {useNavigate} from 'react-router-dom'
function ErrorPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className="h-full flex-1 flex flex-col items-center justify-center">
      <img src={errorIllustration} alt="erreur 404" className="w-4/5 h-4/5" />
      <h1 className="text-xl font-hairline">{t("commons.error")}</h1>
      <p className="text-base">{t("checkout.notFound")}</p>
      <button className="bg-[blue] text-[white] cursor-pointer w-[150px] h-10 rounded-[10px]" onClick={()=>navigate('/')}>{t("commons.comeBack")}</button>
    </div>
  );
}

export default ErrorPage;
