import { StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  header: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  page: {
    fontFamily: "Helvetica",
    fontSize: 11,
    paddingTop: 30,
    paddingLeft: 60,
    paddingRight: 60,
    lineHeight: 1.5,
    flexDirection: "column",
    display: "flex",
  },
  pdf: {
    width: "100vw",
    height: "100vh",
    overflow: "auto",
  },
  logo: {
    width: 74,
    height: 66,
    marginLeft: "auto",
    marginRight: "auto",
  },
  titleContainer: {
    flexDirection: "row",
    marginTop: 24,
  },
  reportTitle: {
    letterSpacing: 1,
    fontSize: 20,
    textAlign: "left",
    textTransform: "uppercase",
  },
  NomContainer: {
    flexDirection: "row",
    marginTop: 36,
    justifyContent: "flex-end",
    fontSize: 9,
  },
  AdresseContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
    fontSize: 9,
  },
  Nom: {
    width: 150,
    fontWeight: "bold",
  },
  Adresse: {
    width: 150,
  },
  date: {
    color: "red",
  },
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 24,
    borderWidth: 1,
    borderColor: "#BFF0FD",
  },
  container: {
    flexDirection: "row",
    borderBottomColor: "#BFF0FD",
    backgroundColor: "#BFF0FD",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    textAlign: "left",
    fontStyle: "bold",
    flexGrow: 1,
  },
  description: {
    paddingLeft: 4,
    width: "40%",
    borderRightColor: "#BFF0FD",
    borderRightWidth: 1,
  },
  qty: {
    width: "15%",
    borderRightColor: "#BFF0FD",
    borderRightWidth: 1,
  },
  rate: {
    width: "10%",
    borderRightColor: "#BFF0FD",
    borderRightWidth: 1,
  },
  amount: {
    width: "15%",
  },
  sign: {
    fontSize: 14,
    flexDirection: "row",
    alignItems: "stretch",
    display: "flex",
  },
});
const tablestyles = StyleSheet.create({
  row: {
    flexDirection: "row",
    borderBottomColor: "#BFF0FD",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 40,
    fontStyle: "bold",
  },
  description: {
    width: "40%",
    textAlign: "left",
    borderRightColor: "#BFF0FD",
    borderRightWidth: 1,
    paddingLeft: 8,
  },
  qty: {
    width: "15%",
    borderRightColor: "#BFF0FD",
    borderRightWidth: 1,
    textAlign: "right",
    paddingRight: 8,
  },
  rate: {
    width: "10%",
    borderRightColor: "#BFF0FD",
    borderRightWidth: 1,
    textAlign: "right",
    paddingRight: 8,
  },
  amount: {
    width: "15%",
    textAlign: "right",
    paddingRight: 8,
  },
});
const footerstyles = StyleSheet.create({
  sign: {
    fontSize: 14,
    marginTop: 20,
    flexDirection: "row",
    alignItems: "stretch",
    justifyContent: "space-between",
    display: "flex",
  },
  row: {
    flexDirection: "row",
    borderBottomColor: "#FFFFFF",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    fontSize: 12,
    fontStyle: "bold",
  },
  description: {
    width: "75%",
    textAlign: "right",
    borderRightColor: "#BFF0FD",
    borderRightWidth: 1,
    paddingRight: 8,
  },
  total: {
    width: "25%",
    textAlign: "right",
    paddingRight: 8,
  },
});
const CIBStyle = StyleSheet.create({
  cib: {
    display: "flex",
    marginTop: 55,
    flexDirection: "column",
  },
});
export { CIBStyle, footerstyles, tablestyles, styles };
