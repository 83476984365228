import axios from "./axios";

export const postCart = (data) => {
  return axios
    .post("/services/carts/", data)
    .then((res) => res)
    .catch((err) => {
      throw err;
    });
};

export const postCartDomain = (data) => {
  return axios
    .post("/services/carts/domain/", data)
    .then((res) => res)
    .catch((err) => {
      throw err;
    });
};

export const postCartCib = (data, CartsId) => {
  return axios
    .post(`services/carts/${CartsId}/check-payment/`, data)
    .then((res) => res)
    .catch((err) => {
      throw err;
    });
};

export const postCodePromo = (data) => {
  return axios
    .post("/services/payments/reduction/", data)
    .then((res) => res)
    .catch((err) => {
      return err.response;
    });
};
export const postEmail = (attach, email) => {
  var formData = new FormData();
  formData.set("email", email);
  formData.set("subject", "Reçu de paiement");
  formData.set(
    "message",
    "Nous vous remercions pour votre achat de pack. Vous trouverez ci-joint votre facture acquittée"
  );
  formData.set("attach", attach);
  return axios
    .post(`/services/checkout-mails/`, formData)
    .then((res) => {
      if (res.status === 201) return "success";
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
};

export const getOrder = (cartId) => {
  return axios
    .get(`/services/cart-payment/${cartId}/`)
    .then((res) => res)
    .catch((err) => {
      return err.response;
    });
};
