import { phoneVerification} from "../../api";
import { saveUser } from "../../redux/actions/user.actions";

export const resendCode = async (data) => {
    try {
      const res = await phoneVerification(data);
      if (res?.status === 429) {
        throw Error("Vous ne pouvez pas vérifier maintenant. Essayer plus tard.");
      } else if (res?.status === 200 || res?.status === 201) {
        return;
      } else if (res === "network error")
        throw Error("Vérifiez votre connexion internet et réessayez");
    } catch (err) {
      throw err;
    }
  };
  export const confirmPhone = async (data, user, dispatch) => {
    try {
      const res = await phoneVerification(data);
      if (res?.status === 406) {
        if (res?.data?.Details === "Code incorrect")
          throw Error(
            "Le code est incorrect. Vérifiez que le code entré est le même que le code envoyé à votre téléphone."
          );
      } else if (res?.status === 429) {
        throw Error("Vous ne pouvez pas vérifier maintenant. Essayer plus tard.");
      } else if (res?.status === 200 || res?.status === 201) {
        user.isconfirmed = true;
        dispatch(saveUser(user));
        return;
      } else if (res === "network error")
        throw Error("Vérifiez votre connexion internet et réessayez");
    } catch (err) {
      throw err;
    }
  };