export function Section({
    children,
    className = `flex flex-col
    `,
  }) {
    return (
      <div
        className={`${className}
          max-w-7xl w-full flex-1 md:px-24 sm:px-12 px-6
          `}
      >
        {children}
      </div>
    );
  }
  export function SubSection({ children, className = "md:py-6 py-2" }) {
    return (
      <div
        className={`${className}
          max-w-7xl w-full flex-1
          flex flex-col       
          `}
      >
        {children}
      </div>
    );
  }