import { Page } from "../component/common/container";
import { Section, SubSection } from "../component/common/section";
import { H1, H2, Ul, Li, P, H4, Span } from "../component/common/title";
import { useTranslation } from "react-i18next";

//page about politique

export default function Politique() {
  const { t } = useTranslation();

  return (
    <Page
      className="flex flex-col items-center justify-center min-h-screen py-2 m-auto overflow-x-hidden
      "
    >
      <div className="flex flex-col w-full flex-1 m-auto justify-center">
        <Section>
          <H1>{t("politics.title")}</H1>
          <H2 className="py-0" textStyle="text-logo text-xl font-500">
            {t("politics.KIOSTOREdata")}
          </H2>
          <SubSection>
            <H2>{t("politics.introduction")}</H2>
            <Ul>
              <Li>
                <Span>
                  
                  {t("politics.politicsIntroduction.list.ligneOne.span")}
                </Span>
                {t(
                  "politics.politicsIntroduction.list.ligneOne.paragraph"
                )}
              </Li>
              <Li>
                <Span>
                  {t("politics.politicsIntroduction.list.ligneTwo.span")}
                </Span>
                {t("politicsIntroduction.ligneTwo.paragraph")}
              </Li>
              <Li>
                <Span>
                  {t("politics.politicsIntroduction.list.ligneThree.span")}
                </Span>
                {t(
                  "politics.politicsIntroduction.list.ligneThree.paragraph"
                )}
              </Li>
              <Li>
                <Span>
                  {t(
                    "politics.politicsIntroduction.list.ligneFour.textOne"
                  )}
                </Span> 
                 {t("politics.politicsIntroduction.list.ligneFour.textTwo")}
                <Span>
                  {t(
                    "politics.politicsIntroduction.list.ligneFour.textThree"
                  )}
                </Span>
                {t(
                  "politics.politicsIntroduction.list.ligneFour.textFour"
                )}
                <Span>
                  {t(
                    "politics.politicsIntroduction.list.ligneFour.textFive"
                  )}
                </Span>
              </Li>
              <Li>
                <Span>
                  {t("politics.politicsIntroduction.list.ligneFive")}
                </Span>
              </Li>
            </Ul>
            <P>{t("politics.politicsIntroduction.paragraph")}</P>
          </SubSection>

          <SubSection>
            <H2>{t("politics.titleOne")}</H2>
            <P>
             {t("politics.politicsParagraphOne")}
            </P>
            <H4>{t("politics.titleTwo")}</H4>
            <P>
            {t("politics.politicsParagraphTwo")}
            </P>
            <H4>{t("politics.titleThree")} </H4>
            <P>
            {t("politics.politicsParagraphThree")}
            </P>
            <H4>
            {t("politics.titleFour")} 
            </H4>
            <P>
            {t("politics.politicsParagraphFour")}
            </P>
          </SubSection>
          <SubSection>
            <H2>{t("politics.title_five")}</H2>
            <P>
            {t("politics.politicsParagraphFive")}
            </P>
            <Ul>
              <Li>{t("politics.paragraphFiveList.ligneOne")}</Li>
              <Li>
              {t("politics.paragraphFiveList.ligneTwo")}
              </Li>
              <Li>
              {t("politics.paragraphFiveList.ligneThree")}
              </Li>
              <Li>{t("politics.paragraphFiveList.ligneFour")}</Li>
              <Li>{t("politics.paragraphFiveList.ligneFive")}</Li>
              <Li>
              {t("politics.paragraphFiveList.ligneSix")}
              </Li>
              <Li>
              {t("politics.paragraphFiveList.ligneSeven")}
              </Li>
            </Ul>
            <P>
            {t("politics.politicsParagraphSix")}
            </P>
            <P>
            {t("politics.politicsParagraphSeven")}
            </P>
          </SubSection>
          <SubSection>
            <H2>Vos droits sur vos informations</H2>
            <P>
            {t("politics.politicsParagraphEight")}
            </P>
            <P>
            {t("politics.politicsParagraphNine")}

            </P>
            <P>
            {t("politics.politicsParagraphTen")}
            </P>
            <P>
            {t("politics.politicsParagraphEleven")}
            </P>
            <P>
            {t("politics.politicsParagraphTwelve")}
            </P>
          </SubSection>

          <SubSection>
            <H2></H2>
            <P>
            {t("politics.politicsParagraphThirteen")}
            </P>
          </SubSection>
          <SubSection>
            <H2>{t("politics.titleSix")} </H2>
            <P>
            {t("politics.politicsParagraphFourteen")}
              <Span className="text-[#0066FF] inline-block sm:inline">
                {t("politics.linkKiostore")}
              </Span>
              .
            </P>
          </SubSection>
          <SubSection>
            <H2>
             {t("politics.titleSeven")}
            </H2>

            <P>
              {t("politics.use")}
              <Span className="text-[#0066FF] ">{t("politics.cookies")} </Span>
              {t("politics.politicsParagraphFifteen")}
              <Span className="text-[#0066FF] ">
               {t("politics.politicsCookies")}
              </Span>
            </P>
          </SubSection>
          <SubSection>
            <H2>{t("politics.contact")}</H2>
            <P>
            {t("politics.politicsParagraphSixteen")}
              <Span className="text-[#0066FF] ">{t("politics.assistance")}</Span> {t("politics.politicsParagraphSeventeen")}
              <Span>{t("politics.legal")}</Span> .
            </P>
            <P>{t("politics.kio")}</P>
            <P>{t("politics.adresse")}</P>
            <P>{t("mention.Algérie")}</P>
          </SubSection>
        </Section>
      </div>
    </Page>
  );
}
