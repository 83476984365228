import { Page, Document, View, Text } from "@react-pdf/renderer";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  InvoiceDates,
  InvoiceTableHeader,
  InvoiceTableRow,
  InvoiceTitle,
  InvoiceTo,
} from "./common";
import {
  CIBStyle,
  footerstyles,
  styles,
  tablestyles,
} from "./styles/invoiceToPdf";
import {
  footerstylesPrint,
  stylesPrint,
  tablestylesPrint,
} from "./styles/invoiceToPrint";

const InvoiceTableFooter = ({ invoice }) => {
  const { t } = useTranslation();
  return (
    <>
      <View style={footerstyles.row}>
        <Text style={footerstyles.description}>{t("invoice.subtotal")}</Text>
        <Text style={footerstyles.total}>
          {invoice.net_total} {invoice.net_total_currency}
        </Text>
      </View>
      {invoice.reduction != null && (
        <View style={footerstyles.row}>
          <Text style={footerstyles.description}>{t("invoice.reduction")}</Text>
          <Text style={footerstyles.total}>
            {(invoice.reduction?.value).toFixed(0)}%
          </Text>
        </View>
      )}

      <View style={footerstyles.row}>
        <Text style={footerstyles.description}>
          {t("invoice.totalReduction")}
        </Text>
        <Text style={footerstyles.total}>
          {invoice.reduction_total} {invoice.reduction_total_currency}
        </Text>
      </View>
      <View style={footerstyles.row}>
        <Text style={footerstyles.description}> {t("invoice.paidOn")}</Text>
        <Text style={footerstyles.total}>
          {invoice.paid === false
            ? t("invoice.noPaid")
            : new Intl.DateTimeFormat("fr-FR", {
                year: "numeric",
                month: "long",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
              }).format(
                Date.parse(invoice["updated"] ?? "2022-10-09T11:19:26.542970Z")
              )}
        </Text>
      </View>
      <View style={footerstyles.row}>
        <Text style={footerstyles.description}> {t("invoice.amount")}</Text>
        <Text style={footerstyles.total}>
          {invoice.gross_total} {invoice.gross_total_currency}
        </Text>
      </View>
    </>
  );
};

const InvoiceTableFooterPrint = ({ invoice }) => {
  const { t } = useTranslation();
  return (
    <>
      <View style={footerstylesPrint.row}>
        <Text style={footerstylesPrint.description}></Text>
        <Text style={footerstylesPrint.total}></Text>
        <Text style={footerstylesPrint.description}></Text>
        <Text style={footerstylesPrint.total}></Text>
        <Text style={footerstylesPrint.description}>
          {t("invoice.subtotal")}
        </Text>
        <Text style={footerstylesPrint.total}>
          {invoice.net_total} {invoice.net_total_currency}
        </Text>
      </View>
      {invoice.reduction != null && (
        <View style={footerstylesPrint.row}>
          <Text style={footerstylesPrint.description}></Text>
          <Text style={footerstylesPrint.total}></Text>
          <Text style={footerstylesPrint.description}></Text>
          <Text style={footerstylesPrint.total}></Text>
          <Text style={footerstylesPrint.description}>
            {t("invoice.reduction")}
          </Text>
          <Text style={footerstylesPrint.total}>
            {(invoice.reduction?.value).toFixed(0)}%
          </Text>{" "}
        </View>
      )}

      <View style={footerstylesPrint.row}>
        <Text style={footerstylesPrint.description}></Text>
        <Text style={footerstylesPrint.total}></Text>
        <Text style={footerstylesPrint.description}></Text>
        <Text style={footerstylesPrint.total}></Text>
        <Text style={footerstylesPrint.description}>
          {t("invoice.totalReduction")}
        </Text>
        <Text style={footerstylesPrint.total}>
          {invoice.reduction_total} {invoice.reduction_total_currency}
        </Text>
      </View>
      <View style={footerstylesPrint.row}>
        <Text style={footerstylesPrint.description}></Text>
        <Text style={footerstylesPrint.total}></Text>
        <Text style={footerstylesPrint.description}></Text>
        <Text style={footerstylesPrint.total}></Text>
        <Text style={footerstylesPrint.description}>{t("invoice.paidOn")}</Text>
        <Text style={footerstylesPrint.total}>
          {invoice.paid === false
            ? "Pas encore payée"
            : new Intl.DateTimeFormat("fr-FR", {
                year: "numeric",
                month: "long",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
              }).format(
                Date.parse(invoice["updated"] ?? "2022-10-10T10:10:10.540670Z")
              )}{" "}
        </Text>
      </View>
      <View style={footerstylesPrint.row}>
        <Text style={footerstylesPrint.description}></Text>
        <Text style={footerstylesPrint.total}></Text>
        <Text style={footerstylesPrint.description}></Text>
        <Text style={footerstylesPrint.total}></Text>
        <Text style={footerstylesPrint.description}>{t("invoice.amount")}</Text>
        <Text style={footerstylesPrint.total}>
          {invoice.gross_total} {invoice.gross_total_currency}
        </Text>
      </View>
    </>
  );
};

const InvoiceItemsTable = ({ invoice, styles, tablestyles, type, t }) => (
  <View style={styles.tableContainer}>
    <InvoiceTableHeader
      item={invoice.item}
      styles={styles}
      t={(x) => {
        return t(x);
      }}
    />
    <InvoiceTableRow
      invoice={invoice}
      tablestyles={tablestyles}
      t={(x) => {
        return t(x);
      }}
    />
    {type === "pdf" ? (
      <InvoiceTableFooter invoice={invoice} />
    ) : (
      <InvoiceTableFooterPrint invoice={invoice} />
    )}
  </View>
);

const Invoice = ({ invoice, successInfos, type = "pdf" }) => {
  const { t } = useTranslation();
  return (
    <Document>
      <Page size="A4" style={type === "pdf" ? styles.page : stylesPrint.page}>
        <InvoiceTo
          customer={invoice.user}
          styles={type === "pdf" ? styles : stylesPrint}
          t={(x) => {
            return t(x);
          }}
        />
        <InvoiceTitle
          title={t("invoice.invoiceNo")}
          successInfos={successInfos}
          styles={type === "pdf" ? styles : stylesPrint}
        />
        <InvoiceDates
          styles={type === "pdf" ? styles : stylesPrint}
          invoice={invoice}
          t={(x) => {
            return t(x);
          }}
        />
        <InvoiceItemsTable
          tablestyles={type === "pdf" ? tablestyles : tablestylesPrint}
          invoice={invoice}
          type={type}
          styles={type === "pdf" ? styles : stylesPrint}
          t={(x) => {
            return t(x);
          }}
        />
        <View style={footerstyles.sign}>
          <Text> {t("invoice.sign")} </Text>
          <Text> {t("invoice.signClient")}</Text>
        </View>
        <View style={CIBStyle.cib}>
          {successInfos.map(({ text, value }, index) => {
            return (
              <Text key={index}>
                {text} {value}
              </Text>
            );
          })}
        </View>
      </Page>
    </Document>
  );
};

export { Invoice };
