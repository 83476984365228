import React, { useState, useEffect } from "react";

/**
 * Counter
 * @description
 * @param {object} props Text props.
 * @param {Number} props.start counter starting value.
 * @param {Function} props.onTimerDone handle counter end.
 * @returns JSX.Element
 * @author
 */
export function Counter({ start = 30, onTimerDone = () => {} }) {
  const [timer, setTimer] = useState({
    minutes:
      parseInt(start / 60) < 10
        ? "0" + parseInt(start / 60)
        : parseInt(start / 60) + "",
    seconds: start % 60 < 10 ? "0" + (start % 60) : (start % 60) + "",
  });
  useEffect(() => {
    var initialTimer = start;
    var newSeconds;
    var newMinutes;
    const counterInterval = setInterval(() => {
      initialTimer -= 1;
      newSeconds = initialTimer % 60;
      newMinutes = parseInt(initialTimer / 60);
      setTimer({
        minutes: newMinutes < 10 ? "0" + newMinutes : newMinutes + "",
        seconds: newSeconds < 10 ? "0" + newSeconds : newSeconds + "",
      });

      if (initialTimer === 0) {
        clearInterval(counterInterval);
        onTimerDone();
      }
    }, 1000);
  }, [start, onTimerDone]);
  return (
    <div className="flex flex-row justify-center py-16 md:py-12">
      <input
        type="text"
        readOnly
        className="w-10 h-10 sm:w-12 sm:h-12 bg-gray-100 mx-1 sm:mx-2 rounded-md shadow-inner	focus:outline-none focus:ring-2 ring-indigo-200 text-center text-lg	font-bold"
        value={timer?.minutes[0]}
      />
      <input
        type="text"
        readOnly
        className="w-10 h-10 sm:w-12 sm:h-12 bg-gray-100 mx-1 sm:mx-2 rounded-md shadow-inner	focus:outline-none focus:ring-2 ring-indigo-200 text-center text-lg	font-bold"
        value={timer?.minutes[1]}
      />
      <div className="flex items-center font-bold">:</div>
      <input
        type="text"
        readOnly
        className="w-10 h-10 sm:w-12 sm:h-12 bg-gray-100 mx-1 sm:mx-2 rounded-md shadow-inner	focus:outline-none focus:ring-2 ring-indigo-200 text-center text-lg	font-bold"
        value={timer?.seconds[0]}
      />
      <input
        type="text"
        readOnly
        className="w-10 h-10 sm:w-12 sm:h-12 bg-gray-100 mx-1 sm:mx-2 rounded-md shadow-inner	focus:outline-none focus:ring-2 ring-indigo-200 text-center text-lg	font-bold"
        value={timer?.seconds[1]}
      />
    </div>
  );
}
