import React, { useState } from "react";
import { useTranslation } from "react-i18next";

export const ToastComponent = ({ svg, message, error }) => {
  const [close, setClose] = useState(true);
  const { t } = useTranslation();

  if (close) setTimeout(() => setClose(false), 5000);
  return (
    close && (
      <div
        id="toast"
        className="flex z-50 bottom-2 inset-x-0  sm:inset-x-[40%] absolute items-center w-full max-w-xs p-4 mb-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800"
        role="alert"
      >
        <div
          className={`inline-flex items-center justify-center flex-shrink-0 w-8 h-8 ${
            error ? "text-[#F64141]" : "text-green-500"
          }  rounded-lg`}
        >
          {svg}
        </div>
        <div className="ml-3 text-sm font-normal">{message}</div>
        <button
          onClick={() => {
            setClose(false);
          }}
          type="button"
          className="ml-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
          data-dismiss-target="#toast-success"
          aria-label="Close"
        >
          <span className="sr-only">{t("commons.close")}</span>
          <svg
            className="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>
      </div>
    )
  );
};
