import React, { useRef, useEffect } from "react";

export function CodeInput({ setCode, code, onChange = () => {} }) {
    const list = [...Array(6).keys()];
    const inputRef = useRef([]);
    useEffect(() => {
      if (setCode) setCode(Array.from(new Array(6), () => ""));
    }, [setCode]);
    // console.log(code);
    return (
      <div className="flex flex-row justify-center py-16 md:py-12">
        {list.map((key) => (
          <input
            ref={(el) => (inputRef.current[key] = el)}
            key={key}
            type="number"
            onChange={(e) => {
              onChange();
              let value = e.target.value;
              if (+value <= 9 && +value >= 0) {
                code[key] = value;
                setCode([...code]);
              }
              if (value.length > 0 && key + 1 !== 6)
                inputRef.current[key + 1].focus();
            }}
            value={code[key]}
            className="hide-input-arrow w-8 h-8 sm:w-12 sm:h-12 bg-gray-100 mx-1 sm:mx-2 rounded-md shadow-inner ring-2 ring-gray-200	focus:outline-none focus:ring-2 focus:ring-indigo-200 text-center text-lg	font-bold"
          />
        ))}
      </div>
    );
  }