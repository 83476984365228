import { createStore, combineReducers } from "redux";
import { persistStore, persistReducer } from "redux-persist"; // persist store let us save the redux store even when closing tabs
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import IdCartsCib from "./reducers/cartsCib.reducers";
import methodReducer from "./reducers/methode.reducers";
import userReducer from "./reducers/user.reducers";

//creating the global reducer, combining all other reducers from the diffrent applications (imported at the beggining of the file, to check them ctrl+click...)
const rootReducer = combineReducers({
  user: userReducer,
  idCarts:IdCartsCib,
  methodReducer:methodReducer
});

const persistConfig = {
  key: "root",
  storage,
}; //config for persisting the store

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(
  persistedReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export const persistor = persistStore(store);
