import Cookies from "js-cookie";
import { checkRecaptchaToken, getToken, signUp } from "../../api";
import { setAuthToken } from "../../api/axios";
import { saveUser } from "../../redux/actions/user.actions";
const setCookies = (token) => {
  var in10Hours = new Date(new Date().getTime() + 10 * 60 * 60 * 1000);
  Cookies.set("token", token, { expires: in10Hours });
};

export const signIn = async (data) => {
  const mydata = {
    grant_type: "password",
    client_id: process.env.REACT_APP_CLIENT_ID,
    username: data.username,
    password: data.password,
  };

  try {
    const res = await getToken(mydata);
    if (res && res !== "network error") {
      if (res.status === 200) {
        localStorage.setItem("refresh_token", res.data.refresh_token);
        const token = res.data.access_token;
        setAuthToken(token);
        setCookies(token);
        return token;
      } else if (res.status === 400) {
        throw Error("nom d'utilisateur/mot de passe introuvable");
      }
    } else if (res === "network error") {
      throw Error("Pas de connexion a internet");
    }
  } catch (err) {
    throw err;
  }
};

export const signup = async (data, dispatch) => {
  try {
    const res = await signUp(data);
    // console.log(res);
    if (res?.status === 400) {
      if (
        res?.data?.email &&
        res?.data?.email[0] === "user with this email address already exists."
      )
        throw Error(
          JSON.stringify({ field: "email", msg: "Email est déjà existe" })
        );
      else if (res?.data?.username) {
        if (
          res?.data?.username[0] === "user with this username already exists."
        )
          throw Error(
            JSON.stringify({
              field: "username",
              msg: "Nom d'utilisateur est déjà existe",
            })
          );
        else if (
          res?.data?.username[0] ===
          "Enter a valid username. This value may contain only letters, numbers, and @/./+/-/_ characters."
        )
          throw Error(
            JSON.stringify({
              field: "username",
              msg: "Nom d'utilisateur est invalide",
            })
          );
      } else if (res?.data?.phone_number) {
        if (
          res?.data?.phone_number[0] ===
          "user with this phone number already exists."
        )
          throw Error(
            JSON.stringify({
              field: "phone_number",
              msg: "Le numéro de téléphone est deja existe",
            })
          );
        else if (
          res?.data?.phone_number[0] ===
          "Phone number must be entered in the format: '+999999999999'. Up to 15 digits allowed."
        ) {
          throw Error(
            JSON.stringify({
              field: "phone_number",
              msg: "La format de numéro de téléphone est invalide",
            })
          );
        }
      } else {
        throw Error(
          JSON.stringify({ field: "connection", msg: res?.data?.Details })
        );
      }
    } else if (res?.status === 201) {
      const mydata = {
        grant_type: "password",
        client_id: process.env.REACT_APP_CLIENT_ID,
        username: res?.data?.username,
        password: data?.password,
      };
      const tokenResponse = await getToken(mydata);
      localStorage.setItem("refresh_token", tokenResponse.data.refresh_token);
      setAuthToken(tokenResponse.data.access_token);
      setCookies(tokenResponse.data.access_token);
      dispatch(saveUser(res?.data));
      return;
    } else if (res?.status === 403) {
      if (res?.data?.Details === "Username reserved.") {
        throw Error(
          JSON.stringify({
            field: "username",
            msg: "Le nom d'utilisateur est réservé.",
          })
        );
      }
    } else if (res === "network error")
      throw Error(
        JSON.stringify({
          field: "connection",
          msg: "Vérifiez votre connexion internet et réessayez",
        })
      );
  } catch (err) {
    console.log(err.message);
    throw err;
  }
};

export const verifyRecaptchaToken = async () => {
  try {
    let token = await window.grecaptcha.execute(
      process.env.REACT_APP_RECAPTCHA_SITE_KEY,
      { action: "submit" }
    );
    const res = await checkRecaptchaToken({ "g-recaptcha-response": token });
    // console.log(res);
    if (res.data?.success !== true || res?.data?.score < 0.5)
      throw new Error(
        JSON.stringify({
          field: "recaptcha",
          msg: "Il ya une erreur. Veuillez reessayer.",
        })
      );
    else return true;
  } catch (_) {
    throw new Error(
      JSON.stringify({
        field: "recaptcha",
        msg: "Il ya une erreur. Veuillez reessayer.",
      })
    );
  }
};

// export const crossDomainAuth = async (setToken, setFetchingToken, dispatch) => {
//   const url = process.env.REACT_APP_DASHBOARD_URL;
//   const ifrm = document.getElementById("ifrm");
//   // console.log("alger");
//   var interval = setInterval(() => {
//     console.log(11);
//     ifrm?.contentWindow?.postMessage("", "*");
//   }, 1000);
  
//   window.addEventListener("message", async (event) => {
//     console.log(event);
//     console.log(event.origin,url)
//     if (event.origin === url) {
//       clearInterval(interval);
//       const data = JSON.parse(event.data);
//       console.log(data);
//       if (data.token) {
//         setToken(data.token);
//         Cookies.set("token", data.token);
//         localStorage.setItem("refresh_token", data.refresh_token);
//         await getUserInformations(dispatch);
//         setFetchingToken(false);
//       }
//     }

//   });
// };

// export const setNewPasswordToken = async (data) => {
//   try {
//     const res = await resetPasswordConfirmation(data);
//     console.log(res);
//     if (res.status === 201) return;
//     else if (res.status === 404)
//       throw Error("Le lien de récuperation est invalide.");
//     else if (res.status === 400)
//       if (
//         res?.data?.password[0] ===
//         "The password is too similar to the email address."
//       )
//         throw Error("Le mot de passe et trop similaire à l'àadresse e-mail.");
//       else throw Error("Ce mot de passe et trop commun.");
//     else if (res === "network error")
//       throw Error("Vérifiez votre connexion internet et réessayez");
//   } catch (err) {
//     throw err;
//   }
// };
