import React from "react";

export function Input({ register,onChange, value,placeholder, error, type = "text" }) {
  return (
    <>
      <input
        className={`h-[40px] mb-[5px] px-[12px] py-[10px] text-[14px] w-full outline outline-0 border rounded-[10px]`}
        placeholder={placeholder} 
        onChange={onChange}
        value={value}
        type={type}
        {...register}
        style={{
          borderColor: error && "red",
        }}
      />
      {error && <div className="text-xs text-red-500">{error}</div>}
    </>
  );
}
