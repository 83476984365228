import axios from "./axios";

const cities_url = "/cities/";
const states_url = "/states/";
const countries_url = "/core/countries/";
const available_domains_url = "/core/availible-domains/";

export const getCities = (source, countryId, stateId) => {
  return axios
    .get(countries_url + countryId + states_url + stateId + cities_url, {
      cancelToken: source.token,
    })
    .then((response) => {
      return response.data;
    })
    .catch((errors) => {
      return errors.response;
    });
};

export const getStates = (source, countryId) => {
  return axios
    .get(countries_url + countryId + states_url, {
      cancelToken: source.token,
    })
    .then((response) => {
      return response.data;
    })
    .catch((errors) => {
      return errors.response;
    });
};

export const getCountries = (source) => {
  return axios
    .get(countries_url, {
      cancelToken: source?.token,
    })
    .then((response) => {
      return response.data;
    })
    .catch((errors) => {
      return errors.response;
    });
};

export const getAvailableDomains = (source) => {
  return axios
    .get(available_domains_url, {
      cancelToken: source.token,
    })
    .then((response) => response.data)
    .catch((errors) => errors.response);
};
