import React, { useState } from "react";
import { Check, Close, LoadingSvg } from "../../image/svg";
import { Space } from "../common/Space";
import Banner from "../common/Banner";
import { verifieCodePromo } from "../../utils/services";
export default function CodePromo({
  packId,
  setReduction,
  code,
  setCode,
  currency,
}) {
  const [codeAppliquer, setCodeAppliquer] = useState(false);
  const [loadingCode, setLoadingCode] = useState(false);
  const [errorCode, setErrorCode] = useState(null);

  const appliquerCode = async () =>  {
    if (code){
    try {
      setLoadingCode(true);
      const res = await verifieCodePromo(packId, code, currency);
      setLoadingCode(false);
      setCodeAppliquer(true);
      setReduction(res);
    } catch (error) {
      setLoadingCode(false);
      setErrorCode(error);
    }}
  };

  return (
    <div>
      <div className="text-[#757575]  font-semibold my-1">Code promo</div>
      {codeAppliquer ? (
        <div className="flex items-center">
          <div
            className="mx-2 hover:border hover:rounded-[6px] hover:text-red-900"
            onClick={() => {
              if (window.confirm("veuiller vraiment supprimer le code")) {
                setReduction(null);
                setCode(null);
                setCodeAppliquer(false);
                setErrorCode(null);
              }
            }}
          >
            <Close />
          </div>
          <div className="uppercase mx-1">{code}</div>
          <div className="text-[#33AD85] mx-1 flex">
            <div> est Appliqué</div>
            <div>
              <Check />
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="xm:flex w-full xm:border items-center rounded-[10px] p-1">
            <div className="xs:w-3/4 m:my-0 mt-1 mb-2">
              <input
                className="w-full outline text-sm outline-0 border xs:border-0 p-2 rounded-[10px]"
                placeholder={"appliquer votre code promo ici"}
                onChange={(e) => {
                  setErrorCode(null)
                  setCode(e.target.value);
                }}
              />
            </div>
            <div className="xm:w-1/4 my-1 xm:my-0 ">
              <button
                onClick={appliquerCode}
                className={` p-2 rounded-[10px] font-semibold text-sm  bg-[#0066FF] text-white w-full flex justify-center ${code ?"opacity-100":"opacity-[50%]"}`}
              >
                {!loadingCode ? "Appliquer" : <LoadingSvg />}
              </button>
            </div>
          </div>
          <Space vertical="2">{errorCode && <Banner text={errorCode} />}</Space>
        </div>
      )}
    </div>
  );
}
