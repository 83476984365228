export function H1({
    children,
    className = "py-7 ",
    style,
    textStyle = "text-graydark md:text-4xl sm:text-3xl text-2xl font-900",
  }) {
    return (
      <h1 style={style} className={`${textStyle} ${className} `}>
        {children}
      </h1>
    );
  }
  export function H2({
    children,
    className = "py-7 ",
    style,
    textStyle = "text-gray md:text-3xl sm:text-2xl text-xl font-800",
  }) {
    return (
      <h2 style={style} className={`${textStyle} ${className} `}>
        {children}
      </h2>
    );
  }
  export function H3({
    children,
    className = "py-7 ",
    style,
    textStyle = "text-graydark md:text-2xl sm:text-xl text-lg font-700",
  }) {
    return (
      <h3 style={style} className={`${textStyle} ${className} `}>
        {children}
      </h3>
    );
  }
  export function H4({
    children,
    className = "py-3 ",
    style,
    textStyle = "text-graydark md:text-xl sm:text-lg text-lg font-600",
  }) {
    return (
      <h4 style={style} className={`${textStyle} ${className} `}>
        {children}
      </h4>
    );
  }
  export function P({
    children,
    className = "pb-5 md:max-w-7xl max-w-5xl",
    style,
    textStyle = " text-[#666]  md:text-base text-sm font-500 text-justify",
  }) {
    return (
      <p style={style} className={`${textStyle} ${className} `}>
        {children}
      </p>
    );
  }
  export function Span({
    children,
    className,
    style,
    textStyle = "text-[#0066FF]",
  }) {
    return (
      <span style={style} className={`${textStyle} ${className} px-1 `}>
        {children}
      </span>
    );
  }
  export function Ul({
    children,
    className = "list-decimal mt-6 md:px-16 sm:px-9 px-6",
    style,
    textStyle = "text-[#666] md:text-lg text-base font-500 text-justify list-disc ",
  }) {
    return (
      <ul style={style} className={`${textStyle} ${className} `}>
        {children}
      </ul>
    );
  }
  export function Li({ children, className, style, textStyle = "" }) {
    return (
      <li style={style} className={`${textStyle} ${className} `}>
        {children}
      </li>
    );
  }