import React, { useCallback, useEffect, useState } from "react";
import { getPacks } from "../api/pack";
import { Price } from "./Price";
import { useTranslation } from "react-i18next";

export function ChoosePacks({setSelection ,setCurrency}) {
  const [pack, setPack] = useState("monthly");
  const listCurrency = ["DZD", "USD", "EUR"];
  const [selectedCurrency, setSelectedCurrency] = useState("DZD");
  const [items, setItems] = useState([]);
  const [selected, setSelected] = useState("Entrepreneur");
  const { t } = useTranslation();
  const handleCurrency =useCallback((e)=>(setSelectedCurrency(e.target.value)),[])
  useEffect(() => {
    getPacks()
      .then((response) => {
        response?.data?.results?.sort((a, b) => {
          return a.pricing - b.pricing;
        });
        setItems(response?.data?.results);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);



  return (
    <div className="flex-1 flex space-y-3 flex-col text-center">
      <div className="font-extrabold flex justify-center items-center text-xl sm:text-4xl ">
        {t("checkout.selectPack")}
      </div>
      <div className=" flex-1 justify-center items-center flex flex-col ">
        <div className=" flex justify-center items-center mb-2 text-sm text-gray-500">
         {t("checkout.chooseCurrency")}
        </div>
        <div className="flex flex-col space-y-2 sm:flex-row justify-center items-center  cursor-pointer sm:space-x-4  sm:space-y-0">
          <div className="flex justify-center  bg-white border  p-[6px]  rounded-lg border-[#E8EBF0] min-w-[12rem] sm:min-w-fit">
            <select className="px-[10px] py-[14px]  bg-white " onChange={handleCurrency}>
              {listCurrency.map((item, id) => {
                return (
                  <option
                    key={id}
                    id={id}
                    value={item}
                  >
                    {item}
                  </option>
                );
              })}
            </select>
          </div>
          <div className=" bg-white  flex rounded-lg border-[#E8EBF0] p-[6px] border">
            <button
              className={`transition duration-400 ${
                pack == "monthly"
                  ? "bg-[#0066FF] text-white hover:shadow-sm"
                  : "bg-white text-gray-500 hover:bg-gray-90"
              } rounded-md px-[10px] py-[14px] mr-2`}
              onClick={() => {
                setSelected("Entrepreneur");
                setPack("monthly");
              }}
            >
              <span>{t("checkout.monthly")}</span>
            </button>
            <button
              className={`transition duration-400 ${
                pack == "annually"
                  ? "bg-[#0066FF] text-white "
                  : "bg-white text-gray-500 hover:bg-gray-90"
              } rounded-md px-[10px] py-[14px] mr-2`}
              onClick={() => {
                setSelected("Entrepreneur");
                setPack("annually");
              }}
            >
              {t("checkout.annually")}
              <span
                className={` ${
                  pack == "annually" ? "text-white " : "text-[#009966]"
                }`}
              >
                {t("checkout.percentage")}
              </span>
            </button>
          </div>
        </div>
      </div>
      <div className="md:space-x-2 md:flex space-y-2 md:space-y-0 mt-4 items-center h-full flex-1">
        {items
          .filter((item) => item.period === pack)
          .map((item) => {
            const name = item.name.split("-");
            let title = name[0];
            let description = JSON.parse(item.description.replaceAll("'", '"'))[
              selectedCurrency
            ];
            let pricing =item.pricing[selectedCurrency]
            return (
              <Price
                key={item.id}
                title={title}
                price={description}
                currency={selectedCurrency}
                period={item.period == "monthly" ? "" : t("checkout.factureAnnually")}
                selected={selected}
                setSelected={setSelected}
                onClick={() => {   
                  localStorage.setItem("currency",selectedCurrency)
                  localStorage.setItem("packId", item.id)
                  setSelection({...item,description,pricing});
                  setCurrency(selectedCurrency)
                }}
              />
            );
          })}
      </div>
    </div>
  );
}
