import Cookies from "js-cookie";
import { refreshExpiredToken, setAuthToken } from "../../api/axios";
import { deleteUser } from "../../redux/actions/user.actions";

let localSetToken;

export const verifyCookie = (
  cookieToken,
  refreshToken,
  setToken,
  setFetchingToken,
  dispatch
) => {
  window.clearInterval(checkCookie);

  localSetToken = setToken;

  if (
    cookieToken === undefined &&
    refreshToken !== undefined &&
    (refreshToken?.length ?? []) > 0
  ) {
    refreshExpiredToken({
      refresh_token: refreshToken,
    })
      .then((res) => {
        if (res?.status === 400) throw Error("no token");
        var in10Hours = new Date(new Date().getTime() + 10 * 60 * 60 * 1000); // sets the date of expiry that is returned in the resp

        Cookies.set("token", res.data.access_token, { expires: in10Hours });
        localStorage.setItem("refresh_token", res.data.refresh_token);

        setAuthToken(res.data.access_token);
        setToken(res.data.access_token);
        setFetchingToken(false);
        window.setInterval(checkCookie, 100); // setting an interval each 100ms (0.1 seconds) to execute the check cookie function ( check line 58)
        return;
      })
      .catch((err) => {
        console.error({ err });
        setToken("");
        setFetchingToken(false);
      });
  } else if (cookieToken === undefined && refreshToken === undefined) {
    localStorage.removeItem("refresh_token");
    Cookies.set("token", "");
    dispatch(deleteUser());
    setFetchingToken(false);
    window.setInterval(checkCookie, 100); // setting an interval each 100ms (0.1 seconds) to execute the check cookie function ( check line 58)
    return;
  } else {
    setFetchingToken(false);
    window.setInterval(checkCookie, 100); // setting an interval each 100ms (0.1 seconds) to execute the check cookie function ( check line 58)
  }
};

export const checkCookie = (function () {
  // function to check wheter cookie changed or not
  try {
    var lastCookie = document.cookie; // 'static' memory between function calls (old cookie)

    return function () {
      var currentCookie = document.cookie; // current cookie;
      if (currentCookie !== lastCookie) {
        // if cookie changed
        lastCookie = currentCookie;
        let token =
          Cookies.get("token") !== undefined
            ? Cookies.get("token")
            : "no token";
        localSetToken(token); //set token stored inside the new cookie
      }
    };
  } catch (err) {
    console.errors(err);
  }
})();
