export function Page({ children }) {
    return (
      <div
        className="
       
        h-full m-auto
        overflow-x-hidden 
        "
      >
        {children}
      </div>
    );
  }