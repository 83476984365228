import { ReactComponent as Kio } from "../image/svg/kio.svg";
import { useNavigate } from "react-router-dom";
import frame from "../image/Frame.png";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Input } from "../component/common/Input";
import { InputPassword } from "../component/common/InputPassword";
import { signup, verifyRecaptchaToken } from "../utils/services";
import { Button } from "../component/common/Button";
import Banner from "../component/common/Banner";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Controller } from "react-hook-form";
import { getCountries } from "../api/core.instance";

export default function SignUp() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [emailInput, setEmailInput] = useState(true);
  const [anotherErrors, setAnotherErrors] = useState("");
  const [errorPhone, setErrorPhone] = useState(false);
  const [phone, setPhone] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    control,
  } = useForm({ mode: "onChange" });
  const [countries, setCountries] = useState([]);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false); // to know wheter we are fetching data or not
  //recaptcha initialisation
  useEffect(() => {
    getCountries()
      .then((res) => {
        let countriesList = [];
        res.forEach((c) => {
          countriesList.push(c.country_iso);
        });
        setCountries(countriesList);
      })
      .catch((err) => console.error(err));
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);

      if (!isScriptExist) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }
      if (callback) callback();
    };

    // load the script by passing the URL
    loadScriptByURL(
      "recaptcha-key",
      `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`,
      function () {}
    );

    return () => {
      const script = document.getElementById("recaptcha-key");
      if (script) {
        script.remove();
      }
      const recaptchaElems =
        document.getElementsByClassName("grecaptcha-badge");
      if (recaptchaElems.length) {
        recaptchaElems[0].remove();
      }
    };
  }, []);

  const onSubmit = async (data) => {
    if (data.email || (phone && phone.length > 9)) {
      if (emailInput) data["phone_number"] = null;
      else {
        data["email"] = null;
        data["phone_number"] = "+" + phone;
      }

      try {
        setAnotherErrors("");
        setLoading(true);
        await verifyRecaptchaToken();
        await signup(data, dispatch);
        navigate(`/`);
        setLoading(false);
      } catch (err) {
        let errMessage = JSON.parse(err?.message);

        if (errMessage?.field === "phone_number") {
          setErrorPhone(errMessage?.msg);
        }
        if (
          errMessage?.field !== "connection" &&
          errMessage?.field !== "recaptcha"
        )
          setError(errMessage?.field, {
            type: "manual",
            message: errMessage?.msg,
          });
        else setAnotherErrors(errMessage?.msg);
        setLoading(false);
      }
    } else {
      setErrorPhone(t("signUp.invalidValue"));
    }
  };
  return (
    <div className="flex flex-col overflow-hidden justify-center 3xl:items-center min-h-screen">
      <div
        id="sign-up"
        className="p-5 flex max-w-[1600px] bg-bgImageA bg-no-repeat bg-cover"
      >
        <div className="lg:flex  flex-1 hidden items-center justify-center content-center">
          <img alt="imgSignUp" src={frame} />
        </div>

        <div className="flex-1 flex flex-col items-center justify-center">
          <div id="logo" className="flex justify-center items-center">
            <Kio />
          </div>
          <div
            id="form"
            style={{ maxWidth: "600px" }}
            className="border rounded-[15px] shadow-shadowFram flex flex-col justify-center p-[30px] md:p-[60px]"
          >
            <div className=" flex justify-center text-center break-words font-medium text-black text-2xl md:text-3xl ">
              {t("signUp.start")}
            </div>
            {/*Form*/}
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="my-11">
                {anotherErrors && <Banner text={anotherErrors} />}
                <div className="xs:flex  mb-[5px] w-full">
                  <div className="xs:mr-2 xs:mb[0px] mb-[5px] flex-1">
                    <Input
                      placeholder={t("signUp.name")}
                      register={register("last_name", {
                        required: true,
                      })}
                      error={
                        errors?.last_name
                          ? errors?.last_name?.type === "required"
                            ? t("signUp.required")
                            : t("signUp.invalidValue")
                          : ""
                      }
                    />
                  </div>
                  <div className="xs:ml-2 xs:mb[0px] mb-[5px] flex-1">
                    <Input
                      placeholder={t("signUp.firstName")}
                      register={register("first_name", {
                        required: true,
                      })}
                      error={
                        errors?.first_name
                          ? errors?.first_name?.type === "required"
                            ? t("signUp.required")
                            : t("signUp.invalidValue")
                          : ""
                      }
                    />
                  </div>
                </div>

                {emailInput && (
                  <div className="mb-[15px]">
                    <Input
                      placeholder={t("signUp.email")}
                      register={register("email", {
                        required: true,
                        validate: (value) => {
                          return value.includes("@");
                        },
                      })}
                      error={
                        errors?.email
                          ? errors?.email?.type === "validate"
                            ? t("signUp.include@")
                            : errors?.email?.type === "required"
                            ? t("signUp.required")
                            : errors?.email?.type === "manual"
                            ? errors?.email?.message
                            : t("signUp.invalidValue")
                          : ""
                      }
                    />

                    <div className="w-full flex justify-end">
                      <div
                        className="text-[#0066FF] cursor-pointer"
                        onClick={(_) => {
                          setEmailInput(false);
                        }}
                      >
                        {t("signUp.byPhone")}
                      </div>
                    </div>
                  </div>
                )}

                {!emailInput && countries.length > 0 && (
                  <div className="mb-[15px] ">
                    <Controller
                      render={() => {
                        return (
                          <PhoneInput
                            countryCodeEditable={false}
                            country={"dz"}
                            onlyCountries={countries}
                            onChange={(value) => {
                              setErrorPhone("");
                              setPhone(value);
                            }}
                            isValid={(value, country) => {
                              if (
                                value !== country.dialCode &&
                                country.dialCode === "213"
                              ) {
                                let phone = value.substr(
                                  country.dialCode.length
                                );
                                if (phone[0] === 0) {
                                  setErrorPhone(t("signUp.phoneWithout0"));

                                  return false;
                                } else {
                                  return true;
                                }
                              } else return true;
                            }}
                            inputRef={register}
                            inputStyle={{
                              height: "40px",
                              width: "100%",
                              padding: "10 12",
                              borderRadius: "10px",
                              borderColor: "#e9e9e9",
                            }}
                          />
                        );
                      }}
                      rules={{
                        name: "phone_number",
                      }}
                      name="phone_number"
                      control={control}
                    />
                    {
                      <div className="text-red-500 font-semibold">
                        {errorPhone}
                      </div>
                    }

                    <div className="w-full flex justify-end">
                      <div
                        className="text-[#0066FF]"
                        onClick={(_) => {
                          setEmailInput(true);
                        }}
                      >
                        {t("signUp.byEmail")}
                      </div>
                    </div>
                  </div>
                )}
                <div className="mb-[15px]">
                  <Input
                    register={register("username", {
                      required: true,
                      maxLength: 20,
                      pattern: {
                        value: /^[A-Za-z\u0600-\u06FF][A-Za-z0-9_\u0600-\u06FF]{4,20}$/,
                        message: t("signUp.formatInvalid"),
                      },
                    })}
                    placeholder={t("signUp.userName")}
                    error={
                      errors.username
                        ? errors.username.type === "manual"
                          ? errors.username.message
                          : errors.username.type === "required"
                          ? t("signUp.required")
                          : t("signUp.invalidValue")
                        : ""
                    }
                  />
                </div>

                <div className="mb-[15px]">
                  <InputPassword
                    register={register("password", {
                      required: true,
                      minLength: 9,
                    })}
                    placeholder={t("signUp.password")}
                    error={errors.password ? t("signUp.invalidValue") : ""}
                  />
                </div>
              </div>
              <div className="mb-[30px] w-full">
                <div className="w-full">
                  <Button loading={loading}>{t("signUp.login")} </Button>
                </div>
                <div className="text-center px-11 text-sm mt-[17px] text-[#ABABAB]">
                  {t("signUp.receiveEmails")}
                </div>
              </div>
            </form>
          </div>

          <div className="my-[30px] flex justify-center items-center ">
            <span className="text-[#ABABAB]">{t("signUp.member")}</span>

            <span className="text-[#0066FF] cursor-pointer">
              <div
                onClick={() => {
                  navigate(`/`);
                }}
              >
                {" "}
                {t("signUp.connect")}
              </div>
            </span>
          </div>
          <div className="flex justify-center items-center my-5 mt-[55px]">
            <div className="flex justify-around text-[#ABABAB] text-xs">
              <div className="flex px-6 cursor-pointer">
                <div
                  onClick={() => {
                    navigate("/politique");
                  }}
                >
                  {t("signIn.politics")}
                </div>
              </div>
              <div className="flex px-6 cursor-pointer">
                <div
                  onClick={() => {
                    navigate("/mention");
                  }}
                >
                  {t("signIn.mention")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
