import axios from "./axios";

export const uploadMedia = (formData) => {
  return axios
    .post(`/services/files/`, formData)
    .then((response) => {
      return response;
    })

    .catch((error) => {
      throw error;
    });
};
