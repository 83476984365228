import { StyleSheet } from "@react-pdf/renderer";

const stylesPrint = StyleSheet.create({
  fragment: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "auto",
    fontSize: 18,
  },
  header: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  page: {
    fontFamily: "Helvetica",
    fontSize: 18,
    paddingTop: 30,
    paddingLeft: 60,
    paddingRight: 60,
    lineHeight: 1.5,
    flexDirection: "column",
    display: "flex",
  },
  pdf: {
    width: "100vw",
    height: "100vh",
    overflow: "auto",
  },
  logo: {
    width: 74,
    height: 66,
    marginLeft: "auto",
    marginRight: "auto",
  },
  titleContainer: {
    flexDirection: "row",
    marginTop: 24,
  },
  reportTitle: {
    letterSpacing: 1,
    fontSize: 25,
    textAlign: "left",
    textTransform: "uppercase",
  },
  NomContainer: {
    flexDirection: "row",
    marginTop: 36,
    justifyContent: "flex-end",
    fontSize: 9,
  },
  AdresseContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
    fontSize: 9,
  },
  Nom: {
    width: 120,
    fontWeight: "bold",
  },
  Adresse: {
    width: 120,
  },
  date: {
    color: "red",
  },
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 24,
    borderWidth: 1,
    borderColor: "#BFF0FD",
  },
  container: {
    display: "flex",
    flexDirection: "row",
    borderBottomColor: "#BFF0FD",
    backgroundColor: "#BFF0FD",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    textAlign: "left",
    fontStyle: "bold",
    flexGrow: 1,
  },
  description: {
    flex: 1,
    width: "40%",
    borderRightColor: "#BFF0FD",
    borderRightWidth: 1,
  },
  qty: {
    flex: 1,
    width: "15%",
    borderRightColor: "#BFF0FD",
    borderRightWidth: 1,
  },
  rate: {
    flex: 1,
    width: "10%",
    borderRightColor: "#BFF0FD",
    borderRightWidth: 1,
  },
  amount: {
    flex: 1,
    width: "15%",
  },
});

const tablestylesPrint = StyleSheet.create({
  row: {
    display: "flex",
    flexDirection: "row",
    borderBottomColor: "#BFF0FD",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 40,
    fontStyle: "bold",
  },
  description: {
    flex: 1,
    width: "45%",
    fontSize: 14,
    textAlign: "left",
    borderRightColor: "black",
    borderRightWidth: 1,
    paddingLeft: 2,
  },
  qty: {
    width: "10%",
    fontSize: 14,
    borderRightColor: "black",
    borderRightWidth: 1,
    flex: 1,
    paddingRight: 8,
  },
  rate: {
    width: "10%",
    borderRightColor: "black",
    borderRightWidth: 1,
    flex: 1,
    paddingRight: 8,
  },
  amount: {
    width: "15%",
    flex: 1,
    paddingRight: 8,
  },
});
const footerstylesPrint = StyleSheet.create({
  row: {
    display: "flex",
    flexDirection: "row",
    borderBottomColor: "#FFFFFF",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    fontSize: 12,
    fontStyle: "bold",
  },
  description: {
    width: "75%",
    flex: 1,
    borderRightColor: "#BFF0FD",
    borderRightWidth: 1,
    paddingRight: 8,
  },
  total: {
    width: "25%",
    flex: 1,
    paddingRight: 8,
  },
});
export { footerstylesPrint, tablestylesPrint, stylesPrint };
