import React, { useEffect, useState } from "react";
import { StepTwo } from "../component/confirmation/StepTwo";
import { StepThree } from "../component/confirmation/stepThree";
import { ReactComponent as ArrowSmLeftIcon } from "../image/svg/bx-arrow-back.svg";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
 
//function for user confirmation pass on 3 step
export default function ConfirmAccount() {
  const {t}=useTranslation()
  const [confirmationStep, setConfirmationStep] = useState(1);
  const [method, setMethod] = useState(); //confirmation method 0: mail 1:phone
  const [showTimer, setShowTimer] = useState(false);
  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (user) {
      if (user.email !== null && user?.confirmation?.email_confirmed === false)
        setMethod(0);
      else if (
        user.phone_number !== null &&
        user?.confirmation?.phone_confirmed === false
      )
        setMethod(1);
    }
  }, []);
  
  return (
    <div className="flex relative justify-center items-center bg-bgImageA h-screen w-screen">
      <div className="border rounded-[15px] shadow-shadowFram m-4 p-4 xs:p-8 md:p-16 sm:min-w-[600px]">
        <div className="flex flex-row justify-between">
          <div
            onClick={() => {
              // console.log("confimrstep", confirmationStep);
            }}
            className="font-bold text-xl"
          >
            {t("confirmAccount.confirmAccount")}
          </div>
          {confirmationStep === 2 && (
            <div
              onClick={() => {
                if (confirmationStep === 2) setConfirmationStep(1);
                else setConfirmationStep(0);
              }}
            >
              <ArrowSmLeftIcon />
            </div>
          )}
        </div>
        {confirmationStep === 1 ? (
          <StepTwo
            method={method}
            setShowTimer={setShowTimer}
            showTimer={showTimer}
            setConfirmationStep={setConfirmationStep}
          />
        ) : (
          <StepThree
            method={method}
            setShowTimer={setShowTimer}
            setConfirmationStep={setConfirmationStep}
          />
        )}
      </div>
    </div>
  );
}
