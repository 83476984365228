import React, { useState } from "react";
import { ReactComponent as Suffix } from "../../image/svg/Suffix.svg";

export function InputPassword({ placeholder, register, error }) {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <>
      <div
        className={
          "h-[40px] mb-[5px] px-[12px] py-[10px] text-[14px] w-full outline outline-0 border rounded-[10px]"
        }
        style={{
          display: "flex",
          borderColor: error && "red",
          alignItems: "center",
        }}
      >
        <div className=" w-[90%]">
          <input
            className=" outline outline-0 w-full"
            placeholder={placeholder}
            type={showPassword ? "text" : "password"}
            {...register}
            style={{
              borderColor: error && "red",
            }}
          />
        </div>
        <div
          className={`flex w-[10%]  cursor-pointer justify-end text-[#929292] active:text-[#0066FF]`}
          onClick={() => setShowPassword(!showPassword)}
        >
          <Suffix />
        </div>
      </div>
      {error && <div className="text-xs text-red-500">{error}</div>}
    </>
  );
}
