import { Section } from "../common/section";
import { ReactComponent as Close } from "../../image/svg/bx-x.svg";
export  function ConditionCib( {setModelCondition}) {
  return (
    <div
      className="flex bg-[rgba(255,255,255,0.8)] z-50 left-0 absolute text-justify w-full flex-col  items-center justify-center max-h-screen py-2 m-auto overflow-x-hidden 
      "
    >
      <div className="flex flex-col border text-sm max-w-[50rem] overflow-y-auto flex-1  m-auto justify-center md:pb-8 sm:pb-6 pb-4 rounded-lg z-50 bg-white">
        <div className="flex justify-end"> <div className="cursor-pointer" onClick={()=>{setModelCondition(false)}}> <Close/></div></div>
        <Section className="flex flex-col overflow-y-auto">
          <div className=" text-graydark flex justify-center  text-base md:my-2 font-bold  md:font-extrabold" > CONDITIONS GÉNÉRALES</div>
          <div className="my-2">
            www.KIOStore.net (le « Site ») est édité par SARL Kiostore, société
            à responsabilité limitée, dont le siège social est 34 Hai el Mokrani
            ex Cité Hausher Mostaganem, immatriculée au Registre du Commerce de
            Mostaganem (ci-après « KIO »). APPLICATIONS DES CONDITIONS GÉNÉRALES
            DE VENTE Les conditions générales de vente (les “CGV“) détaillées
            ci-dessous s’appliquent à toutes les commandes de produits et
            services passées via le Site (les “Produits“) auprès de KIO par
            toute personne (le “Client“). Le Client doit prendre connaissance
            des CGV préalablement à toute commande (la “Commande“), les CGV
            étant disponibles sur le site.
          </div>
          <div className="my-2">
            KIO se réserve le droit d’adapter ou de modifier à tout moment les
            présentes CGV. La version des CGV applicable à toute vente étant
            celle figurant en ligne sur le site www.KIOStore.net au moment de la
            Commande. En conséquence, le fait de passer une Commande requiert
            l’entière l’adhésion préalable et, sans réserve, aux CGV par le
            Client en cliquant sur le bouton “J’ai lu et j’accepte les
            conditions générales de vente”. INFORMATIONS SUR LE SITE ET
            ACCESSIBILITÉ DU SITE www.KIOStore.net est un site de commerce
            électronique qui appartient et est géré par KIO. Le Site est
            accessible à tous les utilisateurs du réseau internet par principe
            24/24h, 7/7j, sauf interruption, programmée ou non, par KIO ou ses
            prestataires, pour les besoins de sa maintenance et/ou de sécurité
            ou cas de force majeure (tel que défini ci-après). KIO ne saurait
            être tenue responsable de tout dommage, quelle qu’en soit la nature,
            résultant d’une indisponibilité du Site. KIO ne garantit pas que le
            Site sera exempt d’anomalies, erreurs ou bugs, ni que le Site
            fonctionnera sans panne ni interruption.
          </div>
          <div className="my-2">
            Il peut à cet égard déterminer librement et à son entière discrétion
            toute période d’indisponibilité du Site ou de son contenu. KIO ne
            peut non plus être tenue responsable de problèmes de transmission de
            données, de connexion ou d’indisponibilité du réseau. KIO se réserve
            le droit de faire évoluer le Site, pour des raisons techniques ou
            commerciales. Lorsque ces modifications n’altèrent pas les
            conditions de la fourniture des services, de manière substantielle
            et négative, le Client peut être informé des modifications
            intervenues, mais son acceptation n’est pas sollicitée.
          </div>
          <div className="my-2">
            III. INSCRIPTION SUR LE SITE Pour pouvoir passer une Commande, le
            Client (peut s' il le souhaite) préalablement s’inscrire sur le Site
            en créant un compte regroupant les informations du Client (le «
            Compte »). L’inscription du Client sur le Site est validée par KIO
            après vérification du formulaire standard rempli par le Client. Le
            Client reçoit un e-mail de confirmation d’inscription. Lors de la
            création de son Compte, le Client doit s’assurer de l’exactitude et
            de l’exhaustivité des données qu’il fournit. Le Client est tenu de
            toujours mettre à jour ses informations personnelles. En cas
            d’erreur dans le libellé des coordonnées du destinataire, KIO ne
            saurait être tenu responsable de l’impossibilité de livrer des
            Produits. Afin de permettre une inscription ou une connexion plus
            facile et rapide au Site, le Client a la possibilité de pré-remplir
            automatiquement le formulaire d’inscription à l’aide de l’option «
            Messenger Connect ». Pour en savoir plus sur la protection des
            données personnelles lors de leur collecte, leur traitement et leur
            utilisation ainsi que les droits et paramètres optionnels pour
            protéger sa vie privée, le Client peut consulter les politiques de
            confidentialité de Facebook sur le site www.facebook.com. En
            s’inscrivant sur le Site, le Client déclare et garantit à KIO qu’il
            est majeur et a la capacité juridique pour contracter. KIO peut
            supprimer le Compte du Client à tout moment, pour toute raison, à sa
            seule discrétion. PRODUITS Les Produits proposés à la vente sont
            ceux décrits sur le Site au jour de la consultation du Site par le
            Client, dans la limite des stocks disponibles. Ces indications sont
            mises à jour automatiquement en temps réel. Toutefois, une erreur
            dans la mise à jour, quelle qu’en soit l’origine, n’engage pas la
            responsabilité de KIO. A ce titre, KIO ne saurait être tenue
            responsable de l’annulation d’une Commande d’un Produit du fait de
            l’épuisement des stocks. KIO apporte le plus grand soin dans la
            présentation et la description de ses Produits pour satisfaire au
            mieux l’information du Client. Il est toutefois possible que des
            erreurs puissent figurer sur le Site, ce que le Client reconnaît et
            accepte. KIO ne garantit ni la précision ni la sécurité des
            informations transmises ou obtenues au moyen du Site. COMMANDES La
            prise de Commande sur le Site est soumise au respect de la procédure
            mise en place par KIO sur le Site comprenant des étapes successives
            aboutissant à la validation de la Commande. Le Client peut
            sélectionner autant de Produits qu’il le souhaite qui s’ajouteront
            au panier (le “Panier“). Le Panier récapitule les Produits choisis
            par le Client ainsi que les prix et les frais y afférents. Le Client
            pourra librement modifier le Panier avant validation de sa Commande.
            La validation de la Commande vaut confirmation de l’acceptation par
            le Client des CGV, des Produits achetés, de leur prix ainsi que des
            frais associés. Un email de confirmation récapitulant la Commande
            (Produit(s), prix, disponibilité du(es) Produit(s), quantité…) sera
            adressé au Client par KIO. A cet effet, le Client accepte
            formellement l’usage du courrier électronique ou par sms pour la
            confirmation par KIO du contenu de sa Commande. Les factures sont
            disponibles dans la rubrique “mon compte” du Site. REFUS DE TRAITER
            UNE COMMANDE KIO se réserve le droit de retirer à tout moment tout
            Produit affiché sur le Site et de remplacer ou modifier tout contenu
            ou information figurant sur ce dernier. Malgré les meilleurs efforts
            de KIO pour satisfaire les attentes de ses clients, il se peut que
            cette dernière soit amenée à refuser de traiter une Commande après
            avoir adressé au Client l’email de confirmation récapitulant la
            Commande. KIO ne saurait être tenue responsable envers le Client ou
            un tiers des conséquences dommageables du retrait d’un Produit du
            Site, ou du remplacement ou de la modification de tout contenu ou
            information figurant sur ce Site, ou encore du refus de traiter une
            Commande après l’envoi de l’email de confirmation récapitulant la
            Commande. KIO se réserve également le droit de refuser ou d’annuler
            une Commande provenant d’un Client avec lequel elle a un litige sur
            le paiement d’une précédente commande ou une suspicion objective de
            fraude.
          </div>
          <div className="my-2">
            IV. PRIX ET MODALITÉS DE PAIEMENT Les prix des produits sont
            indiqués sur le Site en dinars algérien, TVA comprises. Tous les
            prix affichés sont calculés et incluent la taxe sur la valeur
            ajoutée (TVA) applicable en Algérie. KIO se réserve le droit de
            modifier ses prix à tout moment mais les Produits seront facturés
            sur la base des tarifs en vigueur au moment de l’enregistrement
            et/ou du paiement de la Commande, sous réserve de disponibilité. Les
            Produits sont payables comptant lors de la Commande effective par
            ccp ou en espèces à la livraison.
          </div>
          <div className="my-2">
            V. LIVRAISON Les livraisons sont assurées par les services de
            Yalidine, du samedi au vendredi, en fonction de l’option choisie par
            le Client lors de validation de sa Commande. La livraison s’entend
            du transfert au Client de la possession physique des Produits (la «
            Livraison »). Les frais de Livraison applicables à la Commande sont
            ceux mentionnés sur le Site au moment de la Commande dans la
            rubrique «Livraison». Lorsque KIO se charge de l’acheminement du
            Produit, le risque de perte ou de détérioration du Produit est
            transféré au Client au moment de la Livraison. Par exception, le
            risque est transféré au Client lors de la remise du Produit au
            transporteur lorsque celui-ci est chargé du transport par le Client
            et non par KIO. La Livraison est effectuée à l’adresse de livraison
            indiquée par le Client, étant précisé que celle-ci doit être
            l’adresse de résidence du Client, d’une personne physique de son
            choix ou d’une personne morale (livraison à son entreprise). La
            Livraison ne peut être effectuée ni dans des hôtels, ni à des boîtes
            postales. En cas d’impossibilité d’effectuer la Livraison, due à une
            adresse de livraison erronée ou à l’absence de retrait par le Client
            de sa Commande auprès du point de retrait sélectionné ou de
            Yalidine, aucune réexpédition ne pourra être réalisée et la commande
            est automatiquement annulée. KIO livre les Commandes dans un délai
            maximum de principe de six (6) jours ouvrés pour une Livraison sur
            l’ensemble du territoire, ce délai étant décompté à compter du
            premier jour ouvré après la validation de la Commande. Afin que ces
            délais puissent être respectés, le Client doit s’assurer d’avoir
            communiqué des informations exactes et complètes concernant
            l’adresse de Livraison (tels que, notamment : n° de rue, de
            bâtiment, d’escalier, codes d’accès, noms et/ou numéros
            d’interphone, etc.). KIO ne pourra être tenue responsable du retard
            d’acheminement n’étant pas de son fait ou justifié par un cas de
            force majeure (tel que défini ci-après). En cas de dépassement du
            délai de Livraison, le Client pourra demander l’annulation de la
            vente. Nonobstant ce qui précède, KIO ne pourra être tenue
            responsable des conséquences dommageables consécutives à un retard
            d’acheminement, seul le remboursement du Produit par KIO étant
            possible à l’exclusion de toute autre forme de dédommagement si payé
            par ccp. Les Livraisons peuvent également être réalisées en Point
            sous réserve de l’existence d’un point relais dans la ville de
            livraison du Client. DROIT DE RETRACTATION – REMBOURSEMENTS ET
            RETOURS.
          </div>
          <div className="my-2">
            VI.I. Délai et modalités d’exercice du droit de rétractation Le
            Client non-professionnel dispose d’un délai de trois (3) jours à
            compter de la confirmation de la Commande pour exercer son droit de
            rétractation auprès de KIO, sans avoir à motiver sa décision.
            L’exercice du droit de rétractation peut s’effectuer soit en se
            connectant sur son compte client dans la section « commande », soit
            en notifiant KIO par mail à l’adresse
            commande.particulier@KIOStore.net
          </div>
          <div className="my-2">
            VI.II. Modalités de retour de la Commande dans le cadre du droit de
            rétractation Le droit de rétractation s’exerce sans pénalité. Les
            frais de renvoi des marchandises restant à la charge du web
            acheteur. Cependant, seules les marchandises retournées en parfait
            état de revente, complètes et dans leur emballage d’origine
            (non-ouvert) pourront être remboursées des frais d’envoi. Au-delà du
            délai de rétractation, la vente est ferme et définitive. Le Produit
            doit être retourné dans son emballage d’origine, dans son état
            d’origine, neuf, non déballé. Pour effectuer un retour, le Client
            doit suivre la procédure indiquée sur le bon de retour reçu avec sa
            Commande. Le retour des Produits est à la charge du Client et se
            fait à ses risques.
          </div>
          <div className="my-2">
            VI.III. Remboursement des Produits retournés dans le cadre du droit
            de rétractation Le remboursement de la Commande du client si payé
            par ccp s’effectue au plus tard dans les quatorze (14) jours à
            compter de la date à laquelle elle est informée de la décision du
            Client de se rétracter. Cependant, le remboursement intervient sous
            réserve que KIO ait pu récupérer les Produits objet du retour et de
            la demande de remboursement. KIO effectue le remboursement en
            utilisant le même moyen de paiement que celui qui aura été utilisé
            pour le paiement de la Commande, sauf accord exprès du Client pour
            qu’elle utilise un autre. Si ce moyen de paiement et dans la mesure
            ou a expiré, le Client doit contacter le service-client pour
            modifier le mode de remboursement n’occasionne pas de frais pour le
            consommateur. KIO ne pourra être responsable de remboursement sur un
            moyen de paiement expiré. Le remboursement d’une commande réglée
            avec une e carte cadeau, une carte cadeau ou un avoir, sera
            remboursée automatiquement en avoir. A défaut de respect par le
            Client des présentes CGV, KIO ne pourra procéder au remboursement
            des Produits concernés. Dans tous les cas, les frais de retour sont
            à la charge de KIO si le Produit livré au Client est différent du
            Produit commandé ou s’il est livré endommagé.
          </div>
          <div className="my-2">
            GARANTIES – LIMITATION DE RESPONSABILITE VII.I Limitation de
            responsabilités La responsabilité de KIO à l’égard de tout Produit
            acheté sur le Site est strictement limitée au prix d’achat de ce
            dernier. KIO ne sera en aucun cas responsable des pertes suivantes,
            indépendamment de leur origine : – perte de revenus ou de ventes ​-
            perte d’exploitation ​- perte de profits ou de contrats ​- perte
            d’économies prévues ​- perte de données ​- perte de temps de travail
            ou de gestion ​- préjudice d’image ​- perte de chance, et notamment
            de commander un Produit, ​- préjudice moral. Les documents,
            descriptions et informations relatifs aux Produits figurant sur le
            Site ne sont couverts par aucune garantie, explicite ou implicite, à
            l’exception des garanties prévues par la loi. KIO ne fournit aucune
            garantie concernant tout préjudice qui pourrait être causé par la
            transmission d’un virus informatique, d’un ver, d’une bombe
            temporelle, d’un cheval de Troie, d’un cancelbot, d’une bombe
            logique ou de toute autre forme de routine de programmation conçue
            pour endommager, détruire ou détériorer de toute autre manière une
            fonctionnalité d’un ordinateur ou d’entraver le bon fonctionnement
            de celui-ci, en ce compris toute transmission découlant d’un
            téléchargement de tout contenu effectué par le Client, des logiciels
            utilisés par celui-ci pour télécharger le contenu, du Site ou du
            serveur qui permet d’y accéder. A cet égard, le Client reconnaît
            qu’il est de sa responsabilité d’installer des anti-virus et des
            logiciels de sécurité appropriés sur son matériel informatique et
            tout autre dispositif afin de les protéger contre tout bogue, virus
            ou autre routine de programmation de cet ordre s’avérant nuisible.
            Le Client reconnaît assumer l’ensemble des risques liés à tout
            contenu téléchargé ou obtenu de toute autre manière par le biais de
            l’utilisation du Site et convient qu’il est seul responsable de tout
            dommage causé à son système informatique ou de toute perte de
            données résultant du téléchargement de ce contenu. KIO est
            uniquement tenue de livrer des Produits conformes aux dispositions
            contractuelles. Les Produits sont considérés comme étant conformes
            aux dispositions contractuelles si les conditions suivantes sont
            réunies : (i) ils doivent être conformes à la description et
            posséder les caractéristiques exposées sur le Site ; (ii) ils
            doivent être adaptés aux fins pour lesquelles des produits de ce
            genre sont généralement conçus ; (iii) ils doivent répondre aux
            critères de qualité qui sont généralement admis pour des produits du
            même genre et auxquels on peut raisonnablement s’attendre. En outre,
            KIO garantit les consommateurs des défauts de conformité et des
            vices cachés pour les Produits en vente sur le Site dans les
            conditions suivantes :
          </div>
          <div className="my-2">
            VII.II Garanties légales Tous les produits en vente sur le Site
            bénéficient de la garantie légale de conformité et de la garantie
            contre les vices cachés, permettant au Client de renvoyer sans frais
            les Produits livrés défectueux ou non conformes. Dans le cadre de la
            garantie légale des vices cachés, KIO, au choix du Client, s’engage,
            après évaluation du vice : – Soit à rembourser la totalité du prix
            du Produit retourné, – Soit à lui rembourser une partie du prix du
            produit si le Client décide de conserver le Produit. Exclusion de
            garanties Sont exclus de garantie les Produits modifiés, réparés,
            intégrés ou ajoutés par le Client. La garantie ne jouera pas pour
            les vices apparents. La garantie ne prendra pas en charge les
            Produits endommagés lors du transport après la Livraison ou du fait
            d’une mauvaise utilisation.
          </div>
          <div className="my-2">
            VII.III Modalités de mise en oeuvre des garanties Pour toute demande
            concernant les garanties légales, le Client doit alors contacter le
            Service Relation Clientèle à service.client@KIOStore.net ou par
            téléphone au 0561934266, du dimanche au jeudi de 8h à 17h. Ces
            dispositions ne sont pas exclusives du droit de rétraction défini à
            l’Article 9 ci-dessus.
          </div>
          <div className="my-2">
            VII.IV Conséquences de la mise en oeuvre des garanties légales Dans
            le cadre de la garantie légale de conformité, KIO, s’engage au choix
            du Client: – soit à remplacer le Produit par un produit identique en
            fonction des stocks disponibles, – soit à rembourser le prix du
            Produit si le remplacement d’un Produit s’avérait impossible. Dans
            le cadre de la garantie légale des vices cachés, KIO, selon le choix
            du Client, s’engage, après évaluation du vice : – soit à lui
            rembourser la totalité du prix du Produit retourné, soit à lui
            rembourser une partie du prix du Produit si le Client décide de
            conserver le Produit. X.V Force Majeures En cas de survenance d’un
            événement de force majeure empêchant l’exécution des présentes CGV,
            KIO en informe le Client dans un délai de quinze (15) jours à
            compter de la survenance de cet événement, par mail ou par lettre
            recommandée avec accusé de réception. De façon expresse, sont
            considérés comme cas de force majeure ou cas fortuit, outre ceux
            habituellement retenus par la jurisprudence des cours et tribunaux
            français, les grèves totales ou partielles, lock-out, émeute, les
            boycottages ou autres actions à caractère industriel ou litiges
            commerciaux, trouble civil, insurrection, guerre, acte de
            terrorisme, intempérie, épidémie, blocage des moyens de transport ou
            d’approvisionnement pour quelque raison que ce soit, tremblement de
            terre, incendie, tempête, inondation, dégâts des eaux, restrictions
            gouvernementales ou légales, modifications légales ou réglementaires
            des formes de commercialisation, panne d’ordinateur, blocage des
            télécommunications, y compris des réseaux de télécommunications
            filaires ou hertziens, et tout autre cas indépendant de la volonté
            des parties empêchant l’exécution normale de la relation
            contractuelle. L’ensemble des obligations des parties sont
            suspendues pendant toute la durée de l’événement de force majeure,
            sans indemnité. Si l’événement de force majeure se prolonge pendant
            plus de trois (3) mois, la transaction concernée pourra être
            résiliée à la demande de KIO ou du Client sans indemnité de part et
            d’autre. Le défaut de paiement par le Client ne peut être justifié
            par un cas de force majeure. RÉSERVE DE PROPRIÉTÉ KIO conserve la
            propriété pleine et entière des Produits vendus jusqu’au parfait
            encaissement du prix intégral, en principal, frais, taxes et
            contributions obligatoires compris.
          </div>
          <div className="my-2">
            VIII. INVALIDITÉ PARTIELLE Si une ou plusieurs stipulations des
            présentes CGV sont tenues pour non valides ou déclarées comme telles
            en application d’une loi, d’un règlement ou à la suite d’une
            décision définitive d’une juridiction compétente, les autres
            stipulations garderont toute leur force et leur portée.
          </div>
          <div className="my-2">
            IX. NON-RENONCIATION Aucune tolérance, inaction ou inertie de KIO ne
            pourra être interprétée comme renonciation à ses droits aux termes
            des CGV. XIII. MEDIATION- LOI APPLICABLE – JURIDICTION COMPETENTE La
            vente des Produits est soumise à la loi algérienne. À défaut de
            résolution amiable, tout litige relatif à l’interprétation des CGV,
            à l’exécution ou à la rupture d’une vente, à l’interprétation,
            l’exécution ou la résiliation des présentes est soumis, à défaut
            d’accord amiable, aux tribunaux légalement compétents. INFORMATIONS
            LÉGALES Il est rappelé que le secret des correspondances n’est pas
            garanti sur le réseau Internet et qu’il appartient à chaque
            utilisateur d’Internet de prendre toutes les mesures appropriées de
            façon à protéger ses propres données et/ou logiciels de la
            contamination d’éventuels virus circulant sur Internet. EDITEUR KIO,
            société à responsabilité limitée, dont le siège social 34 Hai el
            Mokrani ex Cité Hausher Mostaganem, Algérie. Contact :
            service.client@KIOStore.net DONNÉES PERSONNELLES ET COOKIES Toutes
            les informations de votre Compte ne sont utilisées que dans le cadre
            de votre relation commerciale avec www.KIOStore.net . Ces
            informations ne sont jamais partagées avec des tiers ou revendues.
            Enfin, vos informations bancaires ne sont jamais en notre
            possession. Le Site utilise des cookies (témoins de connexion) ce
            dont l’utilisateur est informé en arrivant sur le site internet qui
            permettent d’enregistrer des informations relatives à la navigation
            de l’ordinateur sur le site internet. Ces cookies ne sont installés
            qu’après acceptation par l’utilisateur, la poursuite de la
            navigation sur le site internet valant acceptation. L’utilisateur
            peut s’opposer à l’utilisation de ces cookies en paramétrant son
            navigateur, sachant que l’accès à certains services peut nécessiter
            l’acceptation préalable par l’utilisateur des cookies.
          </div>
        
        </Section>
      </div>
    </div>
  );
}
