export function Text({
    size = "lg",
    weight = "medium",
    color = "",
    link = false,
    uppercase = false,
    truncate = false,
    italic = false,
    children,
  }) {
    return (
      <div
        className={`${truncate ? "truncate" : ""} ${TEXT_SIZE[size]} ${
          FONT_WEIGHT[weight]
        } ${color} ${link ? "hover:underline cursor-pointer" : ""} ${
          uppercase ? "uppercase" : ""
        } ${italic && "italic"}`}
      >
        {children}
      </div>
    );
  }
  
  const TEXT_SIZE = {
    xs: "text-xs",
    sm: "text-sm",
    base: "text-base",
    lg: "text-lg",
    xl: "text-xl",
    xl2: "text-2xl",
    xl3: "text-3xl",
    xl4: "text-4xl",
    xl5: "text-5xl",
  };
  
  const FONT_WEIGHT = {
    light: "font-light",
    medium: "font-medium",
    bold: "font-bold",
  };