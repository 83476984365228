export const saveUser = (payload) => {
  return {
    payload,
    type: "saveUser",
  };
};

export const deleteUser = () => {
  return {
    type: "deleteUser",
  };
};
