import {
  View,
  Text,
} from "@react-pdf/renderer";
import React, {Fragment} from "react"; 

 const InvoiceTo = ({ customer ,styles ,t}) =>
  customer == null ? (
    <></>
  ) : (
    <Fragment>
      <View style={styles.fragment}>
        <View style={styles.NomContainer}>
          <Text style={styles.Nom}>{customer.full_name}</Text>
        </View>
        <View style={styles.AdresseContainer}>
          <Text style={styles.Adresse}>{t("invoice.address")}</Text>
        </View>
        <View style={styles.AdresseContainer}>
          <Text style={styles.Adresse}>{t("invoice.phone")} {customer.phone_number}</Text>
        </View>
      </View>
    </Fragment>
  );

  const InvoiceTitle = ({ title, successInfos ,styles}) => (
    <View style={styles.titleContainer}>
      <Text style={styles.reportTitle}>
        {title} {successInfos[2].value}
      </Text>
    </View>
  );

const InvoiceDates = ({ invoice,styles ,t}) => (
    <>
      <View style={styles.sign}>
          <Text style={styles.date}> {t("invoice.date")}</Text>
          <Text>
            {new Intl.DateTimeFormat("fr-FR", {
              year: "numeric",
              month: "long",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
            }).format(Date.parse(invoice["created"] ?? "2022-10-09T11:19:26.542956Z"))}
          </Text>
      </View>
    </>
  );
  const InvoiceTableHeader = ({ item,styles ,t}) => (
    <View style={styles.container}>
      <Text style={styles.description}>{item.name}</Text>
      <Text style={styles.qty}>{t("invoice.type")}</Text>
      <Text style={styles.rate}>{t("invoice.PU")}</Text>
      <Text style={styles.rate}>{t("invoice.rem")}</Text>
      <Text style={styles.rate}>{t("invoice.tax")}</Text>
      <Text style={styles.amount}>{t("invoice.sum")}</Text>
    </View>
  );
  const InvoiceTableRow = ({ invoice,tablestyles ,t}) => {
    const packName =invoice.item.value.split("-")[0]
    return (
      <Fragment>
        <View style={tablestyles.row}>
          <Text style={tablestyles.description}>
            {packName}
          </Text>
          <Text style={tablestyles.qty}>
            {invoice.item.value.includes("-an") ? t("invoice.annually") : t("invoice.monthly")}{" "}
          </Text>
          <Text style={tablestyles.rate}>{invoice.item.price} </Text>
          <Text style={tablestyles.rate}>0</Text>
          <Text style={tablestyles.rate}>19%</Text>
          <Text style={tablestyles.amount}>{invoice.net_total}</Text>
        </View>
      </Fragment>
    );
  };

  export { InvoiceTableHeader,InvoiceDates ,InvoiceTitle,InvoiceTo,InvoiceTableRow};