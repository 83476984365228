import React from "react";

export function Space({
  vertical,
  horizontal,
  left,
  right,
  top,
  bottom,
  children,
}) {
  return (
    <div
      className={`w-full ${horizontal ? SPACING_H[horizontal] : ""} ${
        vertical ? SPACING_V[vertical] : ""
      } ${!horizontal && left ? SPACING_LEFT[left] : ""} ${
        !horizontal && right ? SPACING_RIGHT[right] : ""
      } ${!vertical && top ? SPACING_TOP[top] : ""} ${
        !vertical && bottom ? SPACING_BOTTOM[bottom] : ""
      }`}
    >
      {children}
    </div>
  );
}

const SPACING_V = {
  px: "my-px",
  1: "my-1",
  2: "my-2",
  3: "my-3",
  4: "my-4",
};
const SPACING_H = {
  px: "mx-px",
  1: "mx-1",
  2: "mx-2",
  3: "mx-3",
  4: "mx-4",
};
const SPACING_LEFT = {
  px: "ml-px",
  1: "ml-1",
  2: "ml-2",
  3: "ml-3",
  4: "ml-4",
};
const SPACING_RIGHT = {
  px: "mr-px",
  1: "mr-1",
  2: "mr-2",
  3: "mr-3",
  4: "mr-4",
};

const SPACING_TOP = {
  px: "mt-px",
  1: "mt-1",
  2: "mt-2",
  3: "mt-3",
  4: "mt-4",
};

const SPACING_BOTTOM = {
  px: "mb-px",
  1: "mb-1",
  2: "mb-2",
  3: "mb-3",
  4: "mb-4",
};
