import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { Text } from "../common/Text";
import { Button } from "../common/Button";
import { Counter } from "../common/Counter";
import { resendCode, logout } from "../../utils/services";
import { Space } from "../common/Space";
import Banner from "../common/Banner";
import { updateUserInfo } from "../../api";
import { Input } from "../common/Input";
import { useTranslation } from "react-i18next";

export function StepTwo({
  showTimer,
  setShowTimer,
  setConfirmationStep,
  method,
}) {
  const {t}=useTranslation()
  const [newPhoneNumber, setNewPhoneNumber] = useState("");
  const [showModal, setShowModal] = useState(false);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [innerLoading, setInnerLoading] = useState(false);
  // console.log(user);

  const onResendCode = async () => {
    setInnerLoading(true);
    let data = {
      email: method === 0 ? user?.email : null,
      phone_number: method === 0 ? null : user?.phone_number,
      confirmation_code: null,
      resend: true,
    };
    try {
      await resendCode(data);
      setShowTimer(false);
      setInnerLoading(false);
      setConfirmationStep(2);
    } catch (err) {
      console.log(err);
      setShowTimer(false);
      setInnerLoading(false);
      setError(err?.message);
    }
  };
  const updateUser = async () => {
    setInnerLoading(true);
    try {
      const resp = await updateUserInfo({
        phone_number: "+213" + newPhoneNumber.substring(1),
      });
      dispatch({ type: "saveUser", payload: JSON.parse(resp) });
      setInnerLoading(false);
      setShowModal(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Text size="sm" color="text-gray-400">
       {t("confirmAccount.receiveCode")}
      </Text>
      <Space vertical="2">{error && <Banner text={error} />}</Space>
      {showTimer && <Counter onTimerDone={onResendCode} />}

      <div className="flex mt-12 flex-col sm:flex-row">
        <div className="flex-auto">
          <div>
            <Text size="xs">
              {method === 0 ? t("confirmAccount.email") : t("confirmAccount.phone")}
            </Text>
            <Text size="sm" weight="bold">
              {method === 0 ? user?.email : user?.phone_number}
            </Text>
            <div className="mr-2">
              {method === 1 && (
                <span className="text-xs">
                  
                  {t("confirmAccount.notYourPhone")}
                  <span
                    className="font-bold cursor-pointer mx-1"
                    onClick={() => {
                      setShowModal(true);
                    }}
                  >
                    {t("confirmAccount.change")}
                  </span>
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="flex-auto flex flex-row justify-end items-end py-4 sm:py-0">
          <div className="mx-2">
            <Button primary={false} submit={() => logout(navigate, dispatch)}>
             {t("commons.logout")}
            </Button>
          </div>
          {!showTimer && (
            <div className="mx-2">
              
              <Button
                submit={() => {
                  setConfirmationStep(2);
                }}
                loading={innerLoading}
              >
                {t("commons.continue")}
              </Button>
            </div>
          )}
        </div>
      </div>
      {showModal && (
        <div className="absolute inset-0 flex justify-center items-center w-screen h-screen z-20  bg-[rgba(255,255,255,1)]">
          <div className="border rounded-[15px] shadow-shadowFram m-4 p-4 xs:p-8 md:p-16 sm:min-w-[600px]">
           
            <div className="font-bold text-xl">
             
              {t("confirmAccount.changePhone")}
            </div>
            <div className="my-4">
              <Input
                placeholder={t("confirmAccount.newNumber")}
                onChange={(e) => {
                  setNewPhoneNumber(e.target.value);
                }}
              />
            </div>
            <div className="flex justify-end ">
              <div className="mx-2">
                
                <Button
                  primary={false}
                  submit={() => {
                    setShowModal(false);
                  }}
                >
                  
                  {t("commons.cancel")}
                </Button>
              </div>
              <div className="mx-2">
                <Button
                  loading={innerLoading}
                  submit={() => {
                    updateUser();
                  }}
                >
                {t("commons.confirm")}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
