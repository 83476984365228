import React, { useCallback, useEffect, useState } from "react";
import {
  CarteCIB,
  Paypal,
  IconChevronRight,
  IconChevron,
  ErrorSvg,
  SuccessSvg,
  LoadingSvg,
} from "../image/svg";
import { useDispatch, useSelector } from "react-redux";
import baridiMob from "../image/baridiMob.png";
import { ToastComponent } from "../component/common/toastComponant";
import { StatusPayment } from "../component/Model/statusPayment";
import { paymentService, logout, paymentCib } from "../utils/services";
import { getOrder, uploadMedia } from "../api";
import { Progress } from "../component/common/progress";
import { getPacks } from "../api/pack";
import { Loader } from "../component/common/loader";
import { useLocation, useNavigate } from "react-router";
import { ConditionCib } from "../component/Model/conditionCib";
import CodePromo from "../component/codePromo/CodePromo";
import { PaymentMethod } from "../component/common/PaymentMethod";
import { useTranslation } from "react-i18next";
import { ChoosePacks } from "../component/ChoosePacks";

export const Checkout = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const packId = localStorage.getItem("packId");
  const currencyPack = localStorage.getItem("currency");
  const domainData =
    !["", null, undefined].includes(localStorage.getItem("domainData")) &&
    JSON.parse(localStorage.getItem("domainData"));
  const statusPaypal = localStorage.getItem("statusPaypal");

  const [priceDomain, setPriceDomain] = useState(); //  price of domain name
  const cardId = useSelector((state) => state.idCarts); //get cartIn from reducer cartId
  const { search } = useLocation();

  const orderId = search?.substring(1)?.split("=")[
    search?.substring(1)?.split("=")?.indexOf("orderId") + 1
  ]; //get orderId from url and

  const [reduction, setReduction] = useState(); //reduction value
  const [click, setClick] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null); // file 1 for reçu payment
  const [selectedFile2, setSelectedFile2] = useState(null); // file 2 for reçu payment
  const [isSelected, setIsSelected] = useState(false); // state to get if user selects a file
  const [error, setError] = useState(true);
  const [loading, setLoading] = useState(false); // to know wheter we are fetching data or not
  const [fileLoading, setFileLoading] = useState(false); // to know wheter we are uploading a file or no
  const [pageLoading, setPageLoading] = useState(false);
  const [selectedMethod, setSelectedMethod] = useState(null); // methode of payment
  const [modelCondition, setModelCondition] = useState(false); // for display model about condition from payment by CIB
  const [selectName, setSelectName] = useState(); //pack name
  const [check, setCheck] = useState(); // check for accept conditions of Cib
  const [selection, setSelection] = useState(null); //select pack
  const [period, setPeriod] = useState(""); //periode monthly or annualy
  const [code, setCode] = useState(null); //code Promo
  const [payment, setPayment] = useState(false); // if payment perform or no
  const [statusPayment, setStatusPayment] = useState(); //contains payment data
  const [toastOption, setToastOption] = useState({
    show: false,
    message: "",
    error: false,
  }); //toast for file download
  const [order, setOrder] = useState(null);
  const [domain, setDomain] = useState();
  const [currency, setCurrency] = useState();
  const [store, setStore] = useState();

  useEffect(() => {
    if (domainData && !packId) {
      setPageLoading(true);
      setDomain(domainData?.name);
      setCurrency(domainData?.currency);
      setStore(domainData?.store);
      setPageLoading(false);
      setSelectName(domain);
      setPeriod("annually");
      setReduction(null);
      setSelection({
        pricing_currency: currency,
        pricing: priceDomain,
      });
      switch (domainData?.currency) {
        case "DZD":
          setPriceDomain(5000);
          break;
        case "EUR":
          setPriceDomain(50);
          break;
        case "USD":
          setPriceDomain(50);
          break;
        default:
          break;
      }
      setPageLoading(false);
    }
  }, [domain]);

  useEffect(() => {
    if (statusPaypal === "true") {
      setPayment(true);
    } else {
      if (statusPaypal === "false") {
        setPayment(true);
        setStatusPayment({
          status: "error",
          description: t("checkout.errorPayment"),
        });
      }
    }
  }, [statusPaypal]);

  useEffect(() => {
    if (packId && !domain) {
      setPageLoading(true);
      setCurrency(currencyPack);
      getPacks() //get all pack and select the one with the same id as our pack
        .then((response) => {
          response?.data?.results.forEach((item) => {
            if (item.id === packId) {
              item.description = JSON.parse(
                item.description.replaceAll("'", '"')
              )[currencyPack];
              item.pricing = item.pricing[currencyPack];
              setSelection({ ...item });
              setPeriod(item.period);
              setSelectName(item.name.split("-")[0]);
              setPageLoading(false);
            }
          });
        })
        .catch((error) => {
          console.error(error);
          setPageLoading(false);
        });
    }
  }, [packId]);

  const handleClick = () => {
    if (packId && currency === "DZD") {
      setSelectedMethod("RECU");
      setClick(!click);
    }
  };

  const UploadFile = (e) => {
    if (e.target?.files?.length > 0) {
      setError(false);
      setSelectedFile(e.target.files[0]);
      setSelectedFile2(e.target.files[1]);
      setIsSelected(true);

      if (
        (e.target.files[0].size > 10000000) + e.target.files[1]?.size >
        10000000
      ) {
        setError(true);
      }
    } else {
      setSelectedFile(null);
      setSelectedFile2(null);
    }
  };
  useEffect(() => {
    //When orderId is being in url this procedure is done
    if (search.includes("orderId")) {
      const idCart = cardId?.id;
      setPageLoading(true);
      paymentCib(orderId, idCart)
        .then((data) => {
          setPayment(true);
          setStatusPayment(data);
          getOrder(idCart).then((order) => {
            setOrder(order.data);
          });
        })
        .catch((err) => {
          setPayment(true);
          setStatusPayment({
            status: "error",
            description: t("checkout.errorPayment"),
          });
          setPageLoading(false);
          console.log(err);
        });
    }
  }, [orderId]);

  const onSubmit = async () => {
    try {
      setLoading(true);
      dispatch({ type: "saveMethod", payload: selectedMethod }); //dispatch the method of payment
      let fileUrl = "";
      let fileUrl2 = "";

      if (selectedMethod === "RECU") {
        setFileLoading(true);
        //prepare form data
        const formData = new FormData();
        formData.append("file", selectedFile);
        if (selectedFile2) {
          formData.append("file2", selectedFile2);
        }
        formData.append("name", "reçu");
        //
        //upload formData
        const { data, error } = await uploadMedia(formData);
        const { email_confirmed, phone_confirmed } = data;
        setFileLoading(false);
        if (error) throw error;
        if (email_confirmed === false && phone_confirmed === false)
          throw new Error("User is not confirmed.");
        fileUrl = data?.file;
        fileUrl2 = data?.file2;
        setToastOption({
          show: true,
          message: "Télechergement avec succès",
          error: false,
          svg: <SuccessSvg />,
        });
      }

      let data = await paymentService(
        packId,
        selectedMethod,
        fileUrl,
        fileUrl2,
        code,
        currency,
        domain,
        store
      );

      if (data?.url) {
        //  data.url is true when the processing is performed by cib (url of the satime cib)
        window.location = data.url;
        setPayment(false);
        dispatch({ type: "saveId", payload: data.id }); //id de la cart Cib
      } else {
        setLoading(false);
        setClick(false);
        setPayment(true);
        setError(false);
      }
    } catch (error) {
      console.log(error);
      setError(true);
      setToastOption({ show: false });
      setToastOption({
        //toast d'erreur quand il trouve un err
        show: true,
        message: error?.message,
        error: true,
        svg: <ErrorSvg />,
      });
      setLoading(false);
    }
  };

  let textCarte = "font-semibold mx-[10px] text-lg";
  let styleCarte = `flex  items-center py-[26px] xxs:px-[32px] px-[10px] ${
    !(packId && currency === "DZD")
      ? "hidden"
      : " block hover:shadow-boxHover hover:border-[#0066FF]"
  }  shadow-box  border mt-[14px] rounded-[20px] `;

  let styleButton = ` my-5 flex w-full py-[14px] items-center rounded-[10px] bg-[#0066FF] font-medium  active:bg-[#1900ff] mt-[36px] text-white justify-center ${
    error ? "opacity-50 " : ""
  }`;

   const handleChangePack = ()=>{
    setSelection(null)
   }
  return (
    <div className="w-full p-4 h-full  relative bg-bgImageA">
      {payment && (
        <StatusPayment
          data={statusPayment}
          setPayment={setPayment}
          order={order}
        />
      )}

      <div className="flex justify-end w-full">
        <button
          className=" mb-3 rounded-[10px] p-2 bg-[#0066FF] font-medium text-[12px] md:text-[14px] active:bg-[#1900ff] text-white"
          onClick={() => {
            logout(navigate, dispatch);
          }}
        >
          {t("commons.logout")}
        </button>
      </div>

      {modelCondition && (
        <ConditionCib
          check={check}
          setCheck={setCheck}
          setModelCondition={setModelCondition}
        />
      )}

      <div className="h-full md:flex md:justify-center p-8 break-words ">
        {toastOption?.show && (
          <ToastComponent
            error={toastOption?.error}
            message={toastOption?.message}
            svg={toastOption?.svg}
          />
        )}

        {pageLoading ? (
          <div className="flex w-full h-full items-center justify-center">
            <Loader />
          </div>
        ) : !selection ? (
          <div className="flex flex-1 justify-center">
               <ChoosePacks setSelection={setSelection} setCurrency={setCurrency}/>
          </div>
        ) : (
          <div className="relative md:w-1/2">
            <div>
              <div className="text-[#757575]  font-semibold">
                {t("checkout.selectedOffers")}
              </div>
              <div>
                <div className="border py-3 px-[30px] bg-[#F9F9F9] rounded-[20px] my-[13px]">
                  <div className="my-2">
                    <div className="flex">
                      <p
                        className={` ${
                          packId ? "text-[#0000005E]  text-sm" : "font-semibold"
                        }`}
                      >
                        {selectName}
                      </p>
                      {reduction && (
                        <span className="text-[#33AD85] text-xs mx-1 flex">
                          {/* (-{(+reduction?.types_value).toFixed(0)} */}
                          {/* {reduction?.types === "percentage" ? "%" : "DZD"}) */}
                          (-{(+reduction?.types_value) ? "40%":""})

                        </span>
                      )}
                    </div>

                    {!domain && (
                      <div className="flex items-center">
                        <div
                          className={`${
                            reduction?.types === "percentage"
                              ? "text-[#0000005E] line-through"
                              : " text-black text-[17px]"
                          }`}
                        >
                          {selection?.description?.[currency.toUpperCase]?? selection?.description}
                        </div>
                        {reduction?.types === "percentage" && (
                          <div className="mx-1">
                            {(+reduction?.gross_total / 12).toFixed(2)}
                          </div>
                        )}
                        <div className="text-xs mx-2">{currency}</div>
                      </div>
                    )}
                  </div>

                  {period === "annually" && (
                    <>
                      <hr />

                      <div className="my-2">
                        <p className="text-[#0000005E] text-sm ">
                          {t("checkout.billedAnnually")}
                        </p>
                        <div className=" text-[17px] flex items-center ">
                          <div className="flex items-center">
                            {reduction ? (
                              <div className="flex items-center">
                                {reduction?.types === "percentage" ? (
                                  <div className="mx-1">
                                    {reduction?.gross_total}
                                  </div>
                                ) : (
                                  <div className="flex items-center">
                                    <div
                                      className={`text-[#0000005E] line-through `}
                                    >
                                      {selection?.pricing}
                                    </div>

                                    <div className="mx-1">
                                      {reduction?.gross_total}
                                    </div>
                                  </div>
                                )}
                              </div>
                            ) : (
                              selection?.pricing
                            )}
                          </div>
                          <div className="mx-2 text-xs">{currency}</div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            {packId && (
              <div className="flex justify-end">
                <div className="text-[#0066FF] text-xs cursor-pointer font-semibold " onClick={handleChangePack}>
                    {t("checkout.changePackage")}
                </div>
              </div>
            )}
            {period !== "monthly" && packId && (
              <CodePromo
                packId={packId}
                setReduction={setReduction}
                code={code}
                setCode={setCode}
                currency={currency}
              />
            )}
            <div>
              <PaymentMethod
                icon={<CarteCIB />}
                method="CIB"
                selectedMethod={selectedMethod}
                setSelectedMethod={setSelectedMethod}
                cart="Carte CIB"
                disabled={currency !== "DZD"}
              />
              {selectedMethod === "CIB" && (
                <div className="flex my-1">
                  <input
                    type={"checkbox"}
                    checked={check}
                    onClick={(e) => {
                      setCheck(e.currentTarget.checked);
                      setError(check);
                    }}
                  />
                  <div
                    className="font-semibold text-sm underline ml-2 cursor-pointer"
                    onClick={() => {
                      setModelCondition(true);
                      setClick(false);
                    }}
                  >
                    {t("checkout.termsOfUse")}
                  </div>
                </div>
              )}

              <PaymentMethod
                icon={<Paypal />}
                method="PAYPAL"
                selectedMethod={selectedMethod}
                setSelectedMethod={setSelectedMethod}
                cart={"Paypal"}
                disabled={currency === "DZD"}
                setError={setError}
              />

              {!click ? (
                <div
                  className={`${styleCarte} justify-between ${
                    selectedMethod === "RECU"
                      ? "shadow-boxHover border-[#0066FF]"
                      : ""
                  }`}
                  onClick={handleClick}
                >
                  <div className="flex items-center">
                    <div>
                      <img alt="baridiMob" src={baridiMob} />
                    </div>
                    <div className={textCarte}>
                      {t("checkout.paymentByReceipt")}
                    </div>
                  </div>
                  <div>
                    <IconChevronRight />
                  </div>
                </div>
              ) : (
                <div
                  className={`${
                    selectedMethod === "RECU"
                      ? "shadow-boxHover border-[#0066FF]"
                      : ""
                  } hover:shadow-boxHover  hover:border-[#0066FF] py-[26px] xxs:px-[32px] focus:border-[#0066FF] focus:shadow-boxHover shadow-box mt-[14px] p-2 border rounded-[20px] `}
                >
                  <div onClick={handleClick} className=" flex  justify-between">
                    <div className="flex">
                      <div>
                        <img alt="baridiMob" src={baridiMob} />
                      </div>
                      <div className={textCarte}>
                        {t("checkout.paymentByReceipt")}
                      </div>
                    </div>
                    <div>
                      <IconChevron />
                    </div>
                  </div>
                  <div className="text-[#3D3B3B] text-sm py-3 my-2 ">
                    {t("checkout.transferWith")}
                    <span className="text-[#0066FF] mx-1">
                      {t("checkout.amountBilledAnnually")}
                    </span>
                    {t("checkout.account")}
                  </div>
                  <h2 className="mb-[10px] font-bold">
                    {t("checkout.bankTransfer")}
                  </h2>
                  <div className="bg-[#F4F4F4] rounded-[10px] p-[10px]">
                    {t("checkout.banqueTrust")} <br />
                    {t("checkout.SARL")}
                    <br />
                    {t("checkout.RIB")}
                  </div>
                  <h2 className="my-[10px] font-bold">
                    {t("checkout.baridimob")}{" "}
                  </h2>
                  <div className="bg-[#F4F4F4] rounded-[10px] p-[10px]">
                    {t("checkout.numBaridi")}
                  </div>
                  <h2 className="my-[10px] font-bold">{t("checkout.CCP")} </h2>
                  <div className="bg-[#F4F4F4] rounded-[10px] p-[10px]">
                    {t("checkout.numCCP")}
                  </div>
                  <div className="text-[#3D3B3B] my-2 text-sm py-3 ">
                    {t("checkout.scanReceipt")}
                  </div>
                  <div className="border-dashed border-2 rounded-[10px] my-2 flex justify-center items-center xs:p-6 ">
                    <div className="text-center">
                      <div className="text-sm my-2 text-black">
                        {t("checkout.selectFile")}
                      </div>

                      <div className="text-xs my-2 text-[#00000066]">
                        {t("checkout.fileType")}
                      </div>
                      <div className=" text-white">....</div>
                      <label
                        for="file-upload"
                        className=" my-4 border cursor-pointer border-[#0066FF] text-[#0066FF] rounded-[10px] px-1 py-2 xs:py-[10px] "
                      >
                        {t("checkout.selectedFolder")}
                      </label>
                      <input
                        type={"file"}
                        id="file-upload"
                        onChange={UploadFile}
                        multiple
                        className="hidden"
                      />
                      <div className=" text-white">....</div>
                    </div>
                  </div>

                  {isSelected && (
                    <div className="my-4">
                      <div
                        className={`flex justify-between w-full text-sm ${
                          error ? `text-[#F64141]` : "text-[#00000066]"
                        }`}
                      >
                        <div className="flex w-6/12">
                          <div className="truncate w-full">
                            {selectedFile?.name} {selectedFile2?.name}
                          </div>
                        </div>
                        <div>
                          {selectedFile2
                            ? (
                                (0.001 * selectedFile?.size) / 1024 +
                                (0.001 * selectedFile2?.size) / 1024
                              ).toFixed(2)
                            : ((0.001 * selectedFile?.size) / 1024).toFixed(2)}
                          Mo
                        </div>
                      </div>
                      <div>{fileLoading && <Progress />}</div>
                    </div>
                  )}
                </div>
              )}

              <button
                disabled={error}
                onClick={onSubmit}
                className={styleButton}
              >
                {!loading ? (
                  `Payer${
                    reduction
                      ? ` ${reduction?.gross_total} `
                      : ` ${selection?.pricing}`
                  }${currency}`
                ) : (
                  <LoadingSvg />
                )}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
