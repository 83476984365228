import { Page } from "../component/common/container";
import { Section } from "../component/common/section";
import { H1, H3, P } from "../component/common/title";
import { useTranslation } from "react-i18next";
export default function Mention() {
  const {t}=useTranslation()
  return (
    <Page
      className="flex flex-col items-center justify-center min-h-screen py-2 m-auto overflow-x-hidden
      "
    >
      <div className="flex flex-col w-full flex-1 m-auto justify-center md:pb-32 sm:pb-24 pb-20 ">
        <Section>
          <H1>{t("mention.legalNotice")}</H1>

          <div
            className={`flex justify-left items-center space-x-2 
                font-[18px] text-[#8E8C95] mt-14`}
          >
            <svg
              width="65"
              height="3"
              viewBox="0 0 65 3"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect y="0.5" width="65" height="2" fill="#D1D0D6" />
            </svg>
            <h2> {t("mention.legalAdvice")}</h2>
          </div>
          <H3> {t("mention.nameCompany")} </H3>
          <P> {t("mention.NEO")}</P>
          <H3>{t("mention.registration")}</H3>


          <div
            className={`flex justify-left items-center space-x-2 
                font-[18px] text-[#8E8C95] mt-14 pb-7`}
          >
            <svg
              width="65"
              height="3"
              viewBox="0 0 65 3"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect y="0.5" width="65" height="2" fill="#D1D0D6" />
            </svg>
            <h2> {t("mention.contact")}</h2>
          </div>
          <P>{t("mention.cited")}</P>
          <P>{t("mention.street")}</P>
          <P>{t("mention.Algérie")}</P>
          <P>{t("mention.email")} </P>
          <P>{t("mention.tel")} </P>
          <P>{t("mention.fax")} </P>
        </Section>

      </div>
    </Page>
  );
}
