import { Button } from "./common/Button";
import { useTranslation } from "react-i18next";

export function Price({
  title,
  price,
  currency,
  period,
  selected = false,
  setSelected = () => {},
  onClick = () => {},
}) {
  const { t } = useTranslation();

  return (
    <div className="relative flex-1 border rounded-lg flex  justify-center items-center p-4">
      <div
        className={`
      flex flex-col justify-center items-center  space-y-2 p-2 w-full 
     `}
        onClick={() => setSelected(title)}
      >
        <h2 className={`text-2xl font-bold`}>{title}</h2>
        <div className={`flex justify-center items-center space-x-2`}>
          <p className={`text-3xl font-semibold pb-0`}>{price}</p>
          <p className={`text-[13x] font-semibold`}>{currency}</p>
        </div>
        <div
          className={` text-[14px] text-center `}
        >
            <p> {t('checkout.monthly')}</p>
      
          <p >{period}</p>
        </div>
        <div className={`pb-8 w-100 px-2 w-[80%]`}>
          <Button
            className={`rounded-[10px] 
                      ${
                        selected === title
                          ? "bg-white text-logo font-semibold hover:bg-opacity-90"
                          : "bg-logo text-white hover:bg-primary "
                      }`}
            submit={onClick}
          >
            {t('checkout.buy')}
          </Button>
        </div>
      </div>
    </div>
  );
}
