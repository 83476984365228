import React, { useRef, useState } from "react";
import { ReactComponent as Success } from "../../image/svg/success.svg";
import { ReactComponent as IconChevronRight } from "../../image/svg/ic_ChevronRight.svg";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../utils/services";
import { useNavigate } from "react-router";
import { DisplayStatus } from "../common/displayStatus";
import { useTranslation } from "react-i18next";
import { Error, ErrorSvg, SuccessSvg } from "../../image/svg";
import { Invoice } from "./orderToPdf";
import { PDFDownloadLink, pdf } from "@react-pdf/renderer";
import { useReactToPrint } from "react-to-print";
import { Button as ButtonAntd } from "antd";
import { Modal, Input, Form } from "antd";
import { Button } from "../common/Button";
import {
  DownloadOutlined,
  PrinterOutlined,
  MailOutlined,
} from "@ant-design/icons";
import { postEmail } from "../../api";
import { ToastComponent } from "../common/toastComponant";
export function StatusPayment({ data, setPayment, order }) {
  const componentRef = useRef();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const packId = localStorage.getItem("packId");
  const domainData = localStorage.getItem("domainData");

  const paymentMethod = useSelector((state) => state.methodReducer);
  const [innerLoading, setInnerLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [toastOption, setToastOption] = useState({
    show: false,
    message: "",
    error: false,
  });
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleOk = () => {
    setIsModalVisible(false);
  };
  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const onFinishFailed = (errorInfo) => {};

  const [form] = Form.useForm();

  const onSendEmail = async ({ email }) => {
    try {
      setInnerLoading(true);
      const element = (
        <Invoice invoice={order} successInfos={data?.successInfos} />
      );
      const myPdf = pdf();
      myPdf.updateContainer(element);
      const blob = await myPdf.toBlob(); /*create blob*/
      var file = new File([blob], "pdfname.pdf", {
        lastModified: new Date().getTime(),
      }); /*create file*/

      const res = await postEmail(file, email);
      if (res === "success") {
        handleOk();
        setToastOption({
          show: true,
          message: t("statusPayment.successfully"),
          error: false,
          svg: <SuccessSvg />,
        });
      }
    } catch (error) {
      setToastOption({
        show: true,
        message: t("statusPayment.error"),
        error: true,
        svg: <ErrorSvg />,
      });
    }
  };
  return (
    <div className="absolute inset-0 bg-[rgba(255,255,255,0.8)] w-full h-full  z-50 ">
      <div className="flex items-center justify-center w-full h-full my-4 p-4 ">
        <div className="z-30 md:min-w-[40rem] md:min-h-[40rem] xs:min-w-[25rem] xs:min-h-[20rem] rounded-[10px]  p-4 border shadow-shadowFram text-lg font-bold flex bg-[white] flex-col">
          {paymentMethod.id !== "CIB" && !data ? (
            <>
              <DisplayStatus
                title={t("statusPayment.successPayment")}
                onSubmit={(e) => {
                  e.preventDefault();
                  window.open("https://admin.kiostore.net/admin/", "_blank");
                  logout(navigate, dispatch);
                }}
                childrenButton={
                  <>
                    {t("statusPayment.switchToDashboard")}{" "}
                    <span>
                      <IconChevronRight />
                    </span>{" "}
                  </>
                }
              >
                {" "}
                <div className="flex flex-1 justify-center items-center">
                  <Success />
                </div>
              </DisplayStatus>
            </>
          ) : (
            <>
              {data?.status === "success" ? (
                <>
                  <DisplayStatus
                    primary={true}
                    title={t("statusPayment.successPayment")}
                    onSubmit={(e) => {
                      e.preventDefault();
                      window.open(
                        "https://admin.kiostore.net/admin/",
                        "_blank"
                      );
                      logout(navigate, dispatch);
                    }}
                    childrenButton={
                      <>
                        {t("statusPayment.switchToDashboard")}{" "}
                        <span>
                          <IconChevronRight />
                        </span>
                      </>
                    }
                  >
                    <div className="flex flex-col justify-center  text-center">
                      {data?.successInfos?.map(({ text, value }, index) => (
                        <div
                          key={index}
                          className="flex  space-x-2 xs:space-x-5 text-sm py-1"
                        >
                          <div className="font-bold ">{text} </div>
                          <div className="font-normal">{value} </div>
                        </div>
                      ))}
                    </div>
                    {order && (
                      <div className=" flex mt-10 mb-3 justify-center items-center ">
                        <div className="justify-start space-y-2 items-start flex flex-col">
                          <Button size="small" primary={false}>
                            <PDFDownloadLink
                              document={
                                <Invoice
                                  invoice={order}
                                  successInfos={data?.successInfos}
                                />
                              }
                              fileName={`${t("invoice.invoiceNo")} ${
                                data?.successInfos[2]?.text
                              }.pdf`}
                            >
                              <>
                                <span className="mr-2">
                                  <DownloadOutlined />
                                </span>
                                {t("statusPayment.downloadInvoice")}
                              </>
                            </PDFDownloadLink>
                          </Button>
                          <Button
                            primary={false}
                            size="small"
                            submit={() => handlePrint()}
                          >
                            <div className="w-full flex  justify-start">
                              <span className="mr-4">
                                <PrinterOutlined />
                              </span>
                              {t("statusPayment.print")}
                            </div>
                          </Button>
                          <div style={{ display: "none" }}>
                            <div ref={componentRef}>
                              <Invoice
                                invoice={order}
                                successInfos={data?.successInfos}
                                type={"print"}
                              />
                            </div>
                          </div>

                          <Button
                            primary={false}
                            size="small"
                            submit={() => showModal()}
                          >
                            <div className="w-full flex  justify-start">
                              <span className="mr-4">
                                <MailOutlined />
                              </span>
                              {t("statusPayment.sendByMail")}
                            </div>
                          </Button>
                        </div>
                      </div>
                    )}
                  </DisplayStatus>
                </>
              ) : (
                <DisplayStatus
                  primary={paymentMethod.id === "CIB"}
                  title={t("statusPayment.error")}
                  onSubmit={() => {
                    navigate(`/`);
                    setPayment(false);
                  }}
                  childrenButton={
                    <>
                      {t("statusPayment.backToCheckout")}
                      <span>
                        <IconChevronRight />
                      </span>
                    </>
                  }
                >
                  <div className="flex-1 flex flex-col items-center justify-center xs:my-0">
                    <Error />
                    <div className="my-2">{data?.description}</div>
                  </div>
                </DisplayStatus>
              )}
            </>
          )}
        </div>
        <Modal
          title={t("statusPayment.sendInvoiceByEmail")}
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[
            <ButtonAntd form="form" htmlType="submit" loading={innerLoading}>
              {t("commons.send")}
            </ButtonAntd>,
          ]}
        >
          <Form
            onFinish={(data) => onSendEmail(data)}
            onFinishFailed={onFinishFailed}
            form={form}
            id="form"
          >
            <Form.Item
              labelCol={{ span: 24 }}
              label="Email"
              name="email"
              rules={[
                {
                  type: "email",
                  message: t("statusPayment.emailValid"),
                },
                {
                  required: true,
                  message: t("statusPayment.enterEmail"),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Form>
        </Modal>
        {toastOption?.show && (
          <ToastComponent
            error={toastOption?.error}
            message={toastOption?.message}
            svg={toastOption?.svg}
          />
        )}
      </div>
    </div>
  );
}
