import Cookies from "js-cookie";
import {
  getUserInfo,
  postCart,
  postCartCib,
  getUserDevices,
  postCodePromo,
  postCartDomain,
} from "../../api";
import { saveUser, deleteUser } from "../../redux/actions/user.actions";

export const getUserInformations = async (dispatch) => {
  try {
    const user = await getUserInfo();
    let aux = JSON.parse(user);
    dispatch(saveUser(aux));
    return aux;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const paymentService = async (
  id,
  paymentMethod,
  imageUrl = null,
  imageUrl2 = null,
  code,
  currency,
  domain,
  store
) => {
  const bodyPack = {
    payment_method: paymentMethod,
    payment_info: {
      file: imageUrl,
      file2: imageUrl2,
    },
    note: "testing post cart for services",
    items: {
      pack: id,
      currency: currency,
    },
    reduction: code,
  };
  const bodyDomain = {
    payment_method: paymentMethod,
    note: "post cart for services domain",
    reduction: code,
    items: {
      domain: domain,
      currency: currency,
      store: store,
    },
  };
  
  try {
    const { data, error } = domain
      ? await postCartDomain(bodyDomain)
      : await postCart(bodyPack);

    if (error) throw error;
    if (data?.payment_info?.results?.formUrl) {
      const formUrl = {
        url: data?.payment_info?.results?.formUrl,
        id: data?.id,
      };

      return formUrl;
    } else if (paymentMethod === "CIB")
      throw new Error(`${data?.payment_info?.errorMessage}`);
  } catch (error) {
    throw error;
  }
};

export const paymentCib = async (ordersId, CartsId) => {
  const body = {
    order_id: ordersId,
  };
  try {
    const res = await postCartCib(body, CartsId);
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };
    const data = {
      status: "",
      description: "",
      successInfos: {},
    };
    const details = res?.data?.Details;
    if (details) {
      if (
        (details?.params?.respCode === "00" &&
          details?.ErrorCode === "0" &&
          details.OrderStatus === 2) ||
        (details?.params?.respCode === "00" &&
          details?.ErrorCode === "2" &&
          details.OrderStatus === 2)
      )
        data.status = "success";
      else data.status = "error";
      if (Object.keys(details?.params).length !== 0) {
        if (
          (details?.params?.respCode === "00" &&
            details?.ErrorCode === "0" &&
            details.OrderStatus === 2) ||
          (details?.params?.respCode === "00" &&
            details?.ErrorCode === "2" &&
            details.OrderStatus === 2)
        ) {
          data.successInfos = [
            {
              text: "Description :",
              value: details?.params?.respCode_desc,
            },
            {
              text: "Identifiant de la transaction :",
              value: ordersId,
            },
            {
              text: "Le numéro de la commande :",
              value: details?.OrderNumber,
            },
            {
              text: "Le numéro d'autorisation :",
              value: details?.approvalCode,
            },
            {
              text: "Montant :",
              value:
                details?.depositAmount
                  .toString()
                  .replace(
                    new RegExp(
                      `^(.{${details.depositAmount.toString().length - 2}})(.)`
                    ),
                    `$1.$2`
                  ) + " DZD",
            },
            {
              text: "Mode de paiment :",
              value: " Carte CIB",
            },
            {
              text: "Date et heure du paiement :",
              value: new Date().toLocaleDateString("fr-FR", options),
            },
          ];
        } else if (
          details?.params?.respCode === "00" &&
          details?.ErrorCode === "0" &&
          details?.OrderStatus === "3"
        )
          data.description =
            "تم رفض معاملتك/Votre transcation a été rejetée / Your transaction was rejected";
        else data.description = details?.params?.respCode_desc;
      } else {
        data.description = details?.actionCodeDescription;
      }
    } else {
      data.status = "error";
      data.description = "Un erreur est survenue";
    }
    return data;
  } catch (error) {
    throw error;
  }
};

// vérifier le code promo

export const verifieCodePromo = async (id, code, currency) => {
  const body = {
    reduction: code,
    service: {
      pack: id,
      currency: currency,
    },
  };
  try {
    const res = await postCodePromo(body);
    if (res?.data.detail === "Not found.") {
     throw ("Ce code promo n'existe pas");
    }
    if (res?.status === 200) {
      return res.data;
    } else if (res?.status === 400) {
      if (res?.data.Details === "This reduction is not valid") {
        throw ("Ce code promo est invalide");
      }
      if (res?.data.Details === "Unvalid currency") {
        throw ("Le devise de cette réduction n'est pas le même que celui de votre pack");
      }
      if (res?.data.Details ==="reduction, currency & pack are required")
      throw ("réduction, devise & pack sont obligatoires")
    }
  } catch (error) {
    throw error;
  }
};

export const checkUserConfirmation = async (user) => {
  try {
    let res = await getUserDevices();
    if (res?.status === 200) {
      if (res?.data?.phone_confirmed === false && user?.phone_number !== null) {
        return "phone";
      } else if (res?.data?.email_confirmed === false && user?.email !== null)
        return "email";
      else return;
    }
  } catch (err) {
    throw err;
  }
};

export const logout = (navigate, dispatch) => {
  dispatch(deleteUser());
  localStorage.removeItem("refresh_token");
  Cookies.set("token", "");
  navigate(`/`);
};
