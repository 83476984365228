import Cookies from "js-cookie";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route, useSearchParams  } from "react-router-dom";
import { setAuthToken } from "./api/axios";
import "./App.css";
import { Checkout } from "./pages/checkout";
import SignIn from "./pages/signIn";
import { verifyCookie } from "./utils/services";
import { Loader } from "./component/common/loader";
import SignUp from "./pages/signUp";
import Mention from "./pages/mention";
import Politique from "./pages/politique";
import ConfirmAccount from "./pages/ConfirmAccount";
import Page404 from "./pages/404"
import { useTranslation } from "react-i18next";
import { logout } from "../src/utils/services";
import { useNavigate } from "react-router";

function App() {
  /*Token*/
  const [token, setToken] = useState(Cookies.get("token") ?? ""); // the actual token;
  let refreshToken = localStorage.getItem("refresh_token");
  const [params,]=useState(window.location.search);
  const [pathname,]=useState(window.location.pathname);

  /*Token*/

  const [fetchingToken, setFetchingToken] = useState(true);
  // a variable that checks wheter we are refreshing the token or not
  const dispatch = useDispatch();
  //get pack param
  const [searchParams] = useSearchParams();
  const pack = searchParams.get("pack");
  const domain = searchParams.get("domain"); //get domain
  const currency = searchParams.get("currency"); //get currrency
  const store = searchParams.get("store");
  const statusPaypal = searchParams.get("paypal");

 if(statusPaypal ||statusPaypal!==localStorage.getItem("statusPaypal")){
  localStorage.setItem("statusPaypal", statusPaypal);
 }

  const [domainData, setDomainData] = useState();
  const user = useSelector((state) => state.user);

  if (
   pack &&
    localStorage.getItem("packId") !== pack
  ) {
    localStorage.setItem("packId", pack);
    localStorage.setItem("domainData", "");


  }
  if (
    currency &&
     localStorage.getItem("currency") !== currency
   ) {
     localStorage.setItem("currency", currency);
 
   }
  if (
    JSON.stringify(localStorage.getItem("domainData")) !==
    JSON.stringify(domainData) && domain
  ) {
    localStorage.setItem("domainData", JSON.stringify(domainData));
    localStorage.setItem("packId", "");

  }
  useEffect(() => {
    if (domain) {
      setDomainData({
        name: domain,
        store: store,
        currency: currency,
      });
    }
    let cookieToken = Cookies.get("token");

    // console.log(document.referrer.length );

    // if (cookieToken && document.referrer.length === 0) {
    //   //get localstorage and set them in token state
    //   crossDomainAuth(setToken, setFetchingToken, dispatch);
    // } else {

    verifyCookie(
      cookieToken,
      refreshToken,
      setToken,
      setFetchingToken,
      dispatch
    );
    // }
  }, [dispatch,refreshToken]);

  if (!["", undefined, null].includes(token)) {
    setAuthToken(token);
  }

  useEffect(()=>{  
    if (token && pathname.includes('/signup')) {
      setFetchingToken(true)
        window.location.href = '/'+params
    }    
  },[token,pathname,params])
  return (
    <>
      {fetchingToken ? (
        <Loader />
      ) : (
        <Routes>
          {["no token", "", undefined, null].includes(token) ? (
            <>
              <Route path="/" element={<SignIn />} />
              <Route path="signup" element={<SignUp />} />
            </>
          ) : Object.keys(user).length > 0 ? (
            user.isconfirmed ? (
              <>
                <Route path="/" element={<Checkout />} />
              </>
            ) : (
              <Route path="/" element={<ConfirmAccount />} />
            )
          ) : (
            <Route path="/" element={<RouteLoading />} />
          )}
          <Route path="/mention" element={<Mention />} />
          <Route path="/politique" element={<Politique />} />
          <Route path="*" element={<Page404 />} />
        </Routes>
      )}




      {/* iframe of dashboard for authentification */}

      {/* {document.referrer.length === 0 && (
        <iframe
          style={{ display: "none" }}
          title="dash"
          src={process.env.REACT_APP_DASHBOARD_URL + "/admin"}
          id="ifrm"
        ></iframe>
      )} */}
    </>
  );
}

export default App;
const RouteLoading = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <div className="w-full p-4 h-full  relative bg-bgImageA">
      <div className="flex justify-end w-full">
        <button
          className=" mb-3 rounded-[10px] p-2 bg-[#0066FF] font-medium text-[12px] md:text-[14px] active:bg-[#1900ff] text-white"
          onClick={() => {
            logout(navigate, dispatch);
          }}
        >
          {t("commons.logout")}
        </button>
      </div>
      <Loader />
    </div>
  );
};